.footer {
  flex: 0;
  margin-top: 5rem;
  padding: 2rem;

  text-align: center;
}

.footer__link + .footer__link {
  margin-left: 2rem;
}
