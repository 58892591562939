@use '../../utils/mixins';

.notInvited__image {
  margin: 0 auto;
  max-width: 75vw;
}

.notInvited__title,
.notInvited__contact {
  margin-bottom: 0;

  text-align: center;
}

.notInvited__contact {
  margin-left: auto;
  margin-right: auto;
}

.notInvited__image + .notInvited__title {
  margin-top: 4rem;

  @include mixins.medium {
    margin-top: 8rem;
  }
}

.notInvited__title + .notInvited__contact {
  margin-top: 2.4rem;
}

.notInvited__contact + .notInvited__backButton {
  margin-top: 8rem;

  @include mixins.medium {
    margin-top: 10.8rem;
  }
}

.notInvited__backButton {
  @include mixins.medium {
    margin-right: auto;
  }
}
