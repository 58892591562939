@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.matchList__wrapper,
.matchList__matches {
  display: flex;
  flex-direction: column;
}

.matchList__matches {
  list-style-type: none;
  padding-left: 0;

  li + li {
    margin-top: 3.2rem;
  }
}

.matchList__wrapper {
  margin-bottom: 6rem;
}

.matchList__noMatchesFoundButton {
  display: block;
  min-height: 6rem;
  margin: 0 auto 3.2rem auto;

  font-size: 1.8rem;
  @include mixins.medium {
    margin-left: 0;
  }
}

.matchList__notMe,
.matchList__searchAgain {
  font-size: 1.8rem;
  text-align: center;

  @include mixins.medium {
    text-align: left;
  }
}

.matchList__notMe + .matchList__searchAgain {
  margin-top: 3.2rem;
}

.matchForm + .matchList__wrapper {
  margin-top: 4rem;
}

.matchList__instructions + .matchList__matches,
.matchList__matchBtn + .matchList__matchBtn {
  margin-top: 3.2rem;
}

.matchList__matches + .matchList__notMe {
  margin-top: 3.2rem;
  @include mixins.medium {
    margin-top: 8rem;
  }
}

.matchList__matchBtn {
  width: 100%;
  padding: 1.8rem 3.2rem;
  border: 1px solid colors.$color-gray-3;
  box-shadow: map-get(variables.$elevation-shadows, 'modal');
  font-style: normal;
  font-weight: 400;

  &--selected {
    background-color: colors.$color-black;
    color: colors.$color-white;

    svg {
      color: colors.$color-new-gold;
    }
  }

  @include mixins.medium {
    width: fit-content;
  }
}

.buttonNoMatches {
  margin: 30px auto 40rem auto;
  width: 100%;
}

.matchForm {
  margin-bottom: 8rem;
  .submitButton,
  .boxField {
    max-width: unset;
    width: 100%;
    min-height: 6rem;
  }

  @include mixins.large {
    .submitButton,
    .boxField {
      max-width: 48rem;
    }
  }
}

.matching__searchingRightContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.matching__searchingLogo,
.matching__matchListLogo {
  display: none;

  @include mixins.medium {
    display: block;
    margin-top: 2rem;
    max-height: 43rem;
    width: 100%;
    object-fit: contain;
  }
}

.matching__matchListLogo {
  @include mixins.medium {
    max-height: 56rem;
  }
}
