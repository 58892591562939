@use '../utils/colors';
@use '../utils/mixins';

.givingTrafficController {
  margin: 0 auto;
  width: 100vw;
  min-height: 100vh;
  background-color: colors.$color-midnight;
}

.givingTrafficController__header {
  background-color: colors.$color-new-gold;
  padding: 3.2rem 3.2rem 4.8rem 3.2rem;

  @include mixins.medium {
    position: relative;

    padding: 1.2rem 3.2rem 8rem 2.2rem;

    z-index: 1;
  }

  &::before {
    // Extend the background color to both edges of the screen
    // while the content stays centered
    @include mixins.extraLarge {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: colors.$color-new-gold;
      height: 100%;
      width: 100vw;
      z-index: -1;
    }
  }

  &::after {
    // Large toilet paper rollin left
    @include mixins.medium {
      content: '';
      display: block;
      position: absolute;
      top: calc(100vh - 20rem);
      left: -20%;
      height: 18rem;
      width: 45rem;
      background-image: url('../../images/rollin_lg.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
    @include mixins.large {
      top: calc(100% - 3rem);
      left: -08%;
      height: 30rem;
    }
  }
}

.givingTrafficController__wfuLogoLink {
  display: inline-block;

  // The normal focus color new-gold is the same as the background color here, so use midnight instead
  @include mixins.focus(colors.$color-midnight);
}

.givingTrafficController__wfuLogo {
  width: 15rem;
  height: 2rem;
  object-fit: contain;

  @include mixins.medium {
    width: 25rem;
    height: 5rem;
  }
}

.givingTrafficController__title {
  @include mixins.medium {
    margin: 8rem 0 0 0;

    text-align: center;
  }
  @include mixins.large {
    margin: 6rem 45% 0 5%;
  }

  @include mixins.extraLarge {
    margin: 8rem 45% 0 0;
  }

  //Toilet paper rollin middle
  &::before {
    @include mixins.medium {
      content: '';
      display: block;
      position: absolute;
      top: calc(100% - 88px);
      left: 85%;
      height: 13rem;
      width: 50%;
      background-image: url('../../images/rollin_left.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
    @include mixins.large {
      top: calc(100% - 105px);
      left: 45%;
    }
  }

  //Toilet paper rollin right
  &::after {
    @include mixins.medium {
      content: '';
      display: block;
      position: absolute;
      top: calc(100% - 315px);
      left: 70%;
      height: 16rem;
      width: 50%;
      background-image: url('../../images/rollin_right.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
    @include mixins.large {
      height: 18rem;
    }
    @include mixins.extraLarge {
      top: calc(100% - 300px);
      left: 75%;
    }
  }
}

.givingTrafficController__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.8rem;
  text-align: center;

  @include mixins.medium {
    padding-top: 3.8rem;
  }

  @include mixins.large {
    margin-left: 35%;
    padding-top: 5.8rem;
  }

  @include mixins.extraLarge {
    margin-left: 25%;
  }

  p {
    margin: 0;
  }

  p + p {
    margin-top: 2.4rem;

    @include mixins.medium {
      margin-top: 4rem;
    }
  }

  p + .landingPage__googleButton {
    align-self: unset;
    margin-top: 4.4rem;
  }

  .givingTrafficController__or {
    margin: 1.6rem 0;
    color: colors.$color-gray-2;
  }
  .givingTrafficController__goldNetwork {
    color: colors.$color-new-gold;
  }

  .givingTrafficController_continueGuest {
    &:link,
    &:visited {
      color: colors.$color-white;
    }
  }
}
