@use '../utils/colors';

.highlight {
  display: flex;
  gap: 1.2rem;
  margin: 0 auto;
  max-width: 38.4rem;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  padding: 1.2rem 1.2rem 1.2rem 1.6rem;

  &--info {
    border-color: colors.$color-slate;
    background-color: colors.$color-white;

    .highlight__icon {
      color: colors.$color-slate;
    }

    .highlight__message {
      color: colors.$color-slate;
    }
  }

  &--warning {
    border-color: colors.$color-midnight;
    background-color: colors.$color-sunrise;

    .highlight__icon {
      color: colors.$color-black;
    }

    .highlight__message {
      color: colors.$color-black;
    }
  }
}

.highlight + .highlight {
  margin-top: 2.4rem;
}

.highlight__icon {
  margin-top: 0.4rem;

  font-size: 1.6rem;
}

.highlight__message {
  margin: 0;
}
