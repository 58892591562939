@use '../../../utils/mixins';
@use '../../../utils/variables';
@use '../../../utils/colors';

.installmentSchedule {
  border-radius: 7px;
  background: colors.$color-white;
  padding: 2rem;
}

.installmentSchedule__titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.installmentSchedule__title {
  margin: 0;

  font: 700 1.2rem/1.5 variables.$font-stack-default;
  text-transform: uppercase;
  letter-spacing: 0.08em;

  @include mixins.large {
    font-size: 1.4rem;
  }
}

.installmentSchedule__titleWrapper + .installmentSchedule__installments {
  margin-top: 2rem;
}

.installmentSchedule + .installmentsPaid,
.installmentSchedule + .pledge__initialAmountWrapper {
  margin-top: 3rem;
}

.installmentSchedule__installments {
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.installmentSchedule__installment--multiDesignation
  + .installmentSchedule__installment--multiDesignation {
  margin-top: 3rem;
}

.installmentSchedule__installment--multiDesignation .installment__header {
  border-bottom: solid 2px colors.$color-gray-2;
  padding-bottom: 0.5rem;
}
