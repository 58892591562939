@use 'sass:map';
@use '../../utils/variables';
@use '../../utils/mixins';

.taxStatements__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  padding: 3.2rem;

  .boxField {
    margin: 0 auto;
  }
}

.taxStatements__select {
  min-width: 80vw;

  @include mixins.medium {
    min-width: 40rem;
  }
}

.taxStatementInfo__button {
  position: absolute;
  top: 50%;
  right: 1rem;

  z-index: map.get(variables.$z-index, modal-optionsmenu);
  transform: translateY(-50%);
}

.taxStatements__pleaseHold {
  text-align: center;
}

.taxStatements__successContent {
  text-align: center;
}

.taxStatements__expirationMsg {
  text-align: center;
  text-wrap: pretty;
}

.taxStatements__pdfLink {
  display: block;

  & + & {
    margin-top: 1.6rem;
  }
}

.taxStatementInfo__modalMessage + .taxStatementInfo__modalButton {
  margin-top: 2.4rem;
}
