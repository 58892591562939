@use 'sass:map';

@use '../utils/colors';
@use '../utils/mixins';
@use '../utils/variables';

.upcomingDowntimeBanner {
  max-width: 90rem;
  margin: 0 0.8rem;
  box-shadow: map.get(variables.$elevation-shadows, 'container');
  border-radius: 10px;
  border: solid 2px colors.$color-gray-1;
  background-color: colors.$color-black;
  padding: 1.6rem 2.4rem;

  display: flex;
  align-items: center;
  gap: 1.6rem;

  @include mixins.large {
    margin: 0 auto;
  }

  // Gold vertical line
  &::before {
    content: '';
    display: block;
    width: 6px;
    align-self: stretch;
    border-radius: 4px;
    background: colors.$color-sunrise;
  }

  p {
    margin: 0;
    max-width: unset;
    color: colors.$color-white;
  }
}

.navBar + .upcomingDowntimeBanner {
  margin-top: 4rem;

  @include mixins.large {
    margin-top: 7.2rem;
  }
}

.upcomingDowntimeBanner + .app__main {
  margin-top: 3.2rem;

  @include mixins.large {
    margin-top: 6.4rem;
  }
}

.upcomingDowntimeBanner--landingPage {
  @include mixins.medium {
    position: absolute;
    top: 3.2rem;
  }
}

.upcomingDowntimeBanner--landingPage + .app__main {
  margin-top: 1.6rem;

  .landingPage {
    padding-top: 0;
  }
}
