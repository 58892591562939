@use 'sass:map';

@use '../../utils/colors';
@use '../../utils/variables';
@use '../../utils/mixins';

.eventRegDetailsCard {
  box-shadow: map.get(variables.$elevation-shadows, 'hover');
  border-radius: 8px;
  background-color: colors.$color-white;
  padding: 1.6rem;

  @include mixins.medium {
    min-width: 62rem;
    max-width: 50vw;

    padding: 2.4rem;
  }

  & + & {
    margin-top: 6.4rem;

    @include mixins.medium {
      margin-top: 10.4rem;
    }
  }
}

.eventRegDetailsCard__title {
  margin: 0;
  background-color: colors.$color-gray-2;
  padding: 0.8rem;

  &--waitlist {
    background-color: colors.$color-black;
    color: colors.$color-white;
  }
}

.eventRegDetailsCard__title + .eventRegDetailsCard__content {
  margin-top: 2.4rem;
}
