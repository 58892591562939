@use 'sass:map';

@use '../utils/mixins';
@use '../utils/variables';
@use '../utils/colors';

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  width: 100%;
  height: 100%;
  padding: 1.6rem;
  background-color: rgba(colors.$color-midnight, 0.5);

  z-index: map.get(variables.$z-index, modal-overlay);
}

.modal {
  position: relative;
  display: block;
  filter: drop-shadow(map.get(variables.$elevation-shadows, 'modal'));
  border: none;
  border-radius: 10px;
  max-width: 100%;
  padding: 0; // override dialog padding
  z-index: map.get(variables.$z-index, modal);

  text-align: center;

  &--message {
    .modal__closeButton {
      border: none;
      font-size: 2.4rem;
    }
  }

  @include mixins.medium {
    width: auto;
  }
}

.modal__header {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0;
  width: 100%;
  min-height: 5.4rem;
  padding-left: 4.8rem;
  padding-right: 4.8rem;
  background-color: colors.$color-black;

  &--alert {
    background-color: colors.$color-alert;
  }
}

.modal__title {
  color: colors.$color-white;
}

.modal__closeButton {
  position: absolute;
  top: 1.2rem;
  right: 1.6rem;
  width: 2.4rem;
  height: 2.4rem;

  font-size: 1.8rem;
}

.modal__content {
  padding: 2rem;
  margin: 0;

  &--message {
    padding: 5.6rem 1.6rem 2.4rem 1.6rem;

    @include mixins.medium {
      padding: 5.6rem 4rem 3rem 4rem;
    }
  }
}

.modal__content :first-child {
  margin: 0;
}

.modal__subtitle + .modal__detail {
  margin-top: 1.2rem;
}

.modal__theLine {
  margin: 2rem 0 2.4rem 0;
  border-top: 1px solid colors.$color-gray-3;
  max-width: 100%;
}

.modal__detail + .modal__children,
.modal__subtitle + .modal__children {
  margin-top: 3.2rem;
}
