@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.checkoutTracker {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 4rem auto 0 auto;
  padding: 0;
  max-width: 80%;

  list-style-type: none;

  @include mixins.medium {
    max-width: 50rem;
  }
}

.checkoutTrackerStep {
  flex: 1;
  display: flex;
  align-items: center;

  &:first-child {
    flex: 0;
  }

  // Left connector line
  &::before {
    content: '';
    display: block;
    margin-top: 2rem;
    width: 100%;
    border-bottom: solid 10px colors.$color-gray-3;

    transform: translateY(50%);
  }

  &--active {
    // Left connector line
    &::before {
      border-bottom-color: colors.$color-black;
    }
  }
}

.checkoutTracker + .pageLayout {
  margin-top: 3.2rem;

  @include mixins.medium {
    margin-top: 5.6rem;
  }
}

.checkoutTrackerStep__link {
  display: block;
  position: relative;
  min-width: unset;
  background: unset;
  padding: 3rem 0 0 0;

  text-align: center;

  &--clickable {
    &:hover {
      cursor: pointer;
      filter: none;
    }
  }
}

.checkoutTrackerStep__label {
  /*
  Each step's label text is a different length.
  Absolutely positioning the label allows
  the numbered dots to stay evenly spaced.
  */
  position: absolute;
  top: 0;
  left: 50%;

  font: 500 1rem/1.5 variables.$font-stack-default;
  color: colors.$color-black;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  white-space: nowrap;

  transform: translateX(-50%);

  @include mixins.medium {
    font: 500 1.3rem/1.5 variables.$font-stack-default;
  }

  &--active {
    text-decoration: underline;
  }
}

.checkoutTrackerStep__number {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  background-color: colors.$color-gray-3;

  font: 700 2.4rem/1.5 variables.$font-stack-default;
  color: colors.$color-white;

  transition: 0.1s;

  &--active {
    background-color: colors.$color-black;

    color: colors.$color-new-gold;
  }
}
