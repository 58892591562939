@use '../../utils/mixins';

.eventRegistrationFooter {
  margin-top: 6rem;

  @include mixins.medium {
    align-self: stretch;
  }
}

.eventRegistrationFooter__totalCost {
  margin: 0 0 0 auto;

  text-align: center;

  @include mixins.medium {
    text-align: right;
  }
}

.eventRegistrationFooter__totalCost + .eventRegistrationFooter__buttons {
  margin-top: 2.4rem;
}

.eventRegistrationFooter__buttons {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  @include mixins.medium {
    flex-direction: row;
    justify-content: space-between;
  }

  button {
    display: block;
    width: 100%;

    @include mixins.medium {
      width: auto;
    }
  }
}

.eventRegistrationFooter__next {
  @include mixins.medium {
    order: 1;
  }
}
