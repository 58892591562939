@use 'sass:map';

@use '../utils/mixins';
@use '../utils/variables';
@use '../utils/colors';

.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: map.get(variables.$z-index, modal-overlay);
  transform: translateZ(0);
  background-color: rgba(colors.$color-midnight, 0.5);
  border-radius: 0;
}

.profileExternalViewModal {
  flex: 1;
  max-width: 90rem;
  max-height: calc(100vh - 8rem);
  height: 100vh;
  margin: 0 0.8rem;
  box-shadow: map-get(variables.$elevation-shadows, 'modal');
  border-radius: 30px;
  border: solid 1.6rem colors.$color-white;
  background-color: colors.$color-white;
  padding: 3.2rem 0.8rem;

  z-index: map.get(variables.$z-index, modal-profileview);
  overflow-y: scroll;

  @include mixins.large {
    max-height: calc(100vh - 16rem);
    padding: 4rem - 1.6rem 8rem - 1.6rem;
  }

  .profileSectionHeader__titleWrapper {
    width: 100%;
  }
}

.profileExternalViewModal::-webkit-scrollbar-track {
  border-radius: 2rem;
}

.profileExternalView__nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.profileExternalView__nav--withBackBtn {
  justify-content: space-between;
}

.profileExternalView__nav + .profileHeading {
  margin-top: 2rem;

  @include mixins.large {
    margin-top: 4rem;
  }
}
