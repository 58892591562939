@use '../../utils/colors';

.eventAlert {
  flex: 0;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin: 0;
  width: fit-content;
  border-radius: 40px;
  padding: 0.8rem 1.6rem;

  color: colors.$color-white;
  text-transform: uppercase;
  font-size: 1.2rem;
  white-space: nowrap;

  &--nearCapacity {
    background-color: colors.$color-black;
  }

  &--cancelled,
  &--atCapacity {
    background-color: colors.$color-alert;
  }

  &--registered {
    background-color: colors.$color-success;
  }

  &--waitlisted {
    background-color: colors.$color-slate;
  }
}

.eventAlert__wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.8rem;
}

.eventAlert__waitlistMsg {
  margin: 0;
}
