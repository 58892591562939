@use '../../../utils/mixins';
@use '../../../utils/variables';
@use '../../../utils/colors';

.pledgePaymentSelect__section + .pledgePaymentSelect__section {
  margin-top: 4rem;

  @include mixins.large {
    margin-top: 10.4rem;
  }
}

.pledgePaymentSelect__sectionTitle {
  margin: 0;
}

.pledgePaymentSelect__sectionTitle + .pledgePaymentSelect__pledgeWrapper {
  margin-top: 0.8rem;
}

.pledgePaymentSelect__pledges {
  width: 100%;
  padding: 0;
}

.pledgePaymentSelect__sectionTitle + .checkout__sectionBox {
  margin-top: 1.6rem;

  @include mixins.large {
    margin-top: 3.2rem;
  }
}

.pledgePaymentSelect__note {
  margin: 0;

  color: colors.$color-gray-5;
}

.pledgePaymentSelect__note + .pledgePaymentSelect__pledges {
  margin-top: 5.6rem;
}

.selectPaymentForm__paymentAmountLegendWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.selectPaymentForm__radioWrapper--other {
  display: flex;
  align-items: center;
  gap: 4rem;
}

.selectPaymentForm__radio {
  & > .radioLabel {
    // Vertically align radio buttons to the top on mobile
    align-items: unset;

    @include mixins.medium {
      align-items: center;
    }

    &::before,
    &::after {
      margin-top: 0.5rem;

      @include mixins.medium {
        margin-top: 0;
      }
    }

    &::after {
      top: 0.4rem;
      transform: none;

      @include mixins.medium {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.selectPaymentForm__radio + .selectPaymentForm__radio,
.selectPaymentForm__radio + .selectPaymentForm__radioWrapper--other,
.selectPaymentForm__radioWrapper--other + .selectPaymentForm__error {
  margin-top: 3rem;
}

.selectPaymentForm__otherAmountField {
  background: colors.$color-white;
}

.selectPaymentForm__radioWrapper--other + .selectPaymentForm__noteWrapper {
  margin-top: 2.5rem;
}

.selectPaymentForm__noteWrapper {
  border-top: solid 2px #d3d3d3;
  margin-left: 4rem;
  padding-top: 2.5rem;
}

.selectPaymentForm__noteField {
  min-height: 12.5rem;
  padding: 2rem;
}

.selectPaymentForm__error {
  text-align: left;
}
