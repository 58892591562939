@use 'sass:map';

@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.landingPage__intro {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  background-color: colors.$color-white;
  background-repeat: no-repeat;
  background-image: url('../../../components/LandingPage/images/wave-top-mobile.svg');
  background-size: 559px 193px;
  background-position: -150px -15px;
  padding: 5rem 3rem 7rem 3rem;

  @include mixins.medium {
    background-image: url('../../../components/LandingPage/images/wave-top-desktop.svg');
    background-size: 1640px 330px;
    background-position: -450px -40px;
    padding: 15rem 5rem 7rem 5rem;
  }

  @include mixins.large {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  @include mixins.extraLarge {
    gap: 13rem;
    background-position: -300px -40px;
  }
}

.landingPage__introText {
  max-width: 42.5rem;
}

.landingPage__introTitle {
  text-align: center;

  @include mixins.medium {
    text-align: left;
  }

  &--gold {
    color: colors.$color-new-gold;
  }
}

.landingPage__introTitle + .landingPage__introSubtitle {
  margin-top: 2rem;
}

.landingPage__introSubtitle {
  text-align: center;

  @include mixins.medium {
    text-align: left;
  }
}

.landingPage__signInWithIdpButtonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  margin-top: 2.4rem;
}

.landingPage__signInWithIdpButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;

  @include mixins.medium {
    justify-content: unset;
    width: 27rem;
  }

  color: colors.$color-black;

  img {
    width: 3.2rem;
    height: 3.2rem;
  }
}

.landingPage__signInButton {
  flex: 0;
  align-self: flex-start;
  box-shadow: map.get(variables.$elevation-shadows, 'hover');
  border-radius: 15px;
  border: solid 2px colors.$color-gray-1;

  text-align: left;

  transition: 0.2s ease-in-out;

  font-size: 1.8rem;
  color: colors.$color-black;

  @include mixins.large {
    flex: unset;
    align-self: unset;
  }

  &:hover {
    box-shadow: none;
    border-color: colors.$color-black;
    background: colors.$color-black;

    color: colors.$color-white;
  }
}
