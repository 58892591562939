@use 'sass:map';

@use '../utils/mixins';
@use '../utils/variables';
@use '../utils/colors';

$screen-padding: 30px;

.tooltip__wrapper {
  position: relative;
  display: inline-block;
}

.tooltip__triggerButton {
  &:hover + .tooltip,
  // Make sure keyboard users can see tooltip
  &:focus + .tooltip {
    visibility: visible;
    opacity: 1;
  }
}

.tooltip {
  visibility: hidden;
  position: absolute;
  box-shadow: map-get(variables.$elevation-shadows, 'modal');
  border-radius: 18px;
  border: solid 1px colors.$color-gray-2;
  max-width: unset;
  width: calc(100vw - ($screen-padding * 2));
  background-color: colors.$color-white;
  padding: 0.8rem;

  text-align: center;

  z-index: map.get(variables.$z-index, dropdownlist);
  opacity: 0;
  transition: opacity 0.6s;

  @include mixins.medium {
    min-width: 30rem;
    width: auto;
  }

  &--top,
  &--bottom {
    left: 50%;
    transform: translateX(-50%);
  }
}
