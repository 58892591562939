@use 'sass:map';

@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.estate__panelContent {
  padding: 2rem;
}

.estate__saveBtn {
  margin-top: 3rem;
  width: auto;
  padding-left: 5rem;
  padding-right: 5rem;
}

.estate__successMsg {
  max-width: 80rem;

  font-size: 1.8rem;
  font-weight: 400;
  font-family: variables.$font-stack-default;
  line-height: 1.5;

  @include mixins.medium {
    font-size: 2.4rem;
  }
}

.estate__successMsg + .estate__successMsg {
  margin-top: 2rem;
}

.estate__contactWrapper {
  position: relative;
}

.estate__status + .estate__changePrefsBtn,
.estate__status + .estate__contactWrapper,
.estate__changePrefsBtn + .estate__contactWrapper {
  margin-top: 2rem;
}

.estate__contactFormWrapper {
  position: absolute;
  top: calc(100% + 1rem);
  border-radius: 10px;
  box-shadow: map-get(variables.$elevation-shadows, 'modal');
  background: colors.$color-white;
  padding: 1rem;

  @include mixins.medium {
    width: 40rem;
  }
}
