@use '../utils/mixins';
@use '../utils/variables';
@use '../utils/colors';

// General email activation stuff
.emailActivation__formTitle + .emailActivation__formDescrip {
  margin-top: 1rem;
}

.emailActivation__formUsernameWrapper {
  @include mixins.medium {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
  }
}

.emailActivation__formUsernameDomain {
  display: none;

  @include mixins.medium {
    display: block;
  }
}

.emailActivation__formIntro + .emailActivation__formUsernameWrapper,
.emailActivation__formUsernameWrapper + .boxField {
  margin-top: 2.5rem;
}

.emailActivation__activatedEmailWrapper {
  margin-bottom: 0;
}

.emailActivation__formTitle + .emailActivation__activatedEmailWrapper {
  margin-top: 3rem;
}

.emailActivation__email + .emailActivation__activated {
  margin-left: 2rem;
}

.emailActivation__activated {
  color: colors.$color-success;
}

.emailActivation__form + .emailActivation__laterBtn,
.intlPhoneField + .emailActivation__nextBtn,
.emailActivation__formIntro + .emailActivation__nextBtn {
  margin-top: 2rem;
}

.emailActivation__nextBtn {
  width: 100%;
}

.emailActivation__laterBtn {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
