@use '../../../utils/mixins';
@use '../../../utils/variables';
@use '../../../utils/colors';

.editEmployerGroupForm + .employerGroup,
.addRecordButton + .employerGroup {
  margin-top: 4rem;
}

.employerGroup {
  position: relative;
  margin-bottom: 4.4rem;
  margin-top: 2.3rem;

  &:last-child {
    margin-bottom: 0;
  }

  &:only-child {
    margin: 0;

    .employerGroup__innerWrapper {
      border: none;
      padding: 0;
    }
  }
}

.employerGroup__editButton {
  position: absolute;
  top: -2.8rem;
  right: 0;
}

.newRecordForm + .employerGroup {
  margin-top: 4rem;
}

.employerGroup__innerWrapper {
  max-width: 43.5rem;
  border-bottom: solid 2px colors.$color-gray-2;
  padding-bottom: 4.4rem;

  &--withDotLines {
    padding-left: 2rem;
  }

  @include mixins.medium {
    padding-left: 0;
  }
}

.employerGroup__employerWrapper {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.employerGroup__employerWrapper + .addRecordButton,
.employerGroup__employerWrapper + .employerGroup__positions,
.addRecordButton + .employerGroup__positions {
  margin-top: 0.8rem;

  @include mixins.medium {
    margin-top: 1.6rem;
  }
}

.employerGroup__positions {
  margin: 0;
  padding: 0;

  list-style-type: none;
}
