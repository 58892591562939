@use '../../utils/colors';
@use '../../utils/mixins';

.registrationOptionsForm__title {
  text-align: center;
}

.registrationOptionsForm__title + .registrationOptionsForm {
  margin-top: 4.8rem;
}

.registrationOptionsForm__guest + .registrationOptionsForm__guest {
  margin-top: 5.6rem;

  @include mixins.medium {
    margin-top: 7.2rem;
  }
}

.registrationOptionsForm__guestName {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.registrationOptionsForm__userIcon {
  color: colors.$color-gray-3;
}
