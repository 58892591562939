@use '../../utils/mixins';
@use '../../utils/variables';

.checkoutReview__title {
  margin: 0;
}

.checkoutReview__title + .checkoutReview__instructions {
  margin-top: 1.6rem;
}

.checkoutReview__instructions + .checkoutReview__subTitle {
  margin-top: 5.6rem;
}

.checkoutReview__subTitle {
  margin: 0;
}

.checkoutReview__subTitle + .checkout__sectionBox {
  margin-top: 3.2rem;
}

.checkoutReview__contact {
  position: relative;
  padding-top: 3.2rem;

  @include mixins.medium {
    padding: 6.4rem;
  }
}

.checkoutReview__contactInfoLabel {
  margin: 0 0 1.6rem 0;
}

.checkoutReview__contactInfo {
  margin: 0;
}

.checkoutReview__contactEditButton {
  position: absolute;
  top: 1rem;
  right: 1rem;

  @include mixins.medium {
    top: 3.2rem;
    right: 3.2rem;
  }
}

.selectContactForm + .newRecordForm {
  margin-top: 5.6rem;
}

.checkout__sectionBox + .checkoutReview__subTitle {
  margin-top: 8rem;
}

.checkoutReview__sectionBox + .checkout__cancelContinue {
  margin-top: 8.8rem;
}

.checkoutSummary + .checkoutReview__total {
  margin-top: 2rem;
}

.checkoutReview__total {
  margin: 0 auto;
  max-width: 70rem;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;

  // @extend%textSans--18bold;
}

.checkoutReview__dadPostError {
  margin: 2.4rem auto 0 auto;
}

.checkoutContactInfoSkeleton__text + .checkoutContactInfoSkeleton__text {
  margin-top: 2rem;
}

.checkoutContactInfoSkeleton__button {
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
}
