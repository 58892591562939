@use 'sass:map';
@use '../../../utils/colors';
@use '../../../utils/mixins';
@use '../../../base/typography';
@use '../../../utils/variables';

.philanthropyReport__scholarshipContent + .fundFinancialHistory {
  margin-top: 1.6rem;
}

.fundFinancialHistory__content {
  padding-top: 2.4rem;

  @include mixins.medium {
    padding-top: 4rem;
  }
}

.fundFinancialHistory__fyContent {
  padding-bottom: 3.2rem;
}

.fundFinancesByQuarter {
  box-shadow: map.get(variables.$elevation-shadows, 'container');
  border-radius: 10px;
  overflow: hidden;
}

.fundFinancesByQuarter + .highlight {
  margin-top: 2.4rem;
}

.fundFinancialHistory__quarter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid 1px colors.$color-gray-3;
  border-bottom: solid 1px colors.$color-gray-3;
  background: colors.$color-gray-2;
  padding: 0.8rem 1.6rem;

  p {
    margin: 0;
  }
}

.fundFinancialHistory__tableWrapper {
  padding: 0.8rem 1.6rem 1.6rem 1.6rem;
}

.fundFinancialHistory__table {
  border: none;

  tbody tr:first-child td {
    border-top: none;
    padding-top: 0;

    @include mixins.medium {
      padding-top: 2.4rem;
    }
  }

  tbody tr:last-child td {
    border-bottom: none;
  }

  th,
  td {
    border-left: none;
    border-right: none;
  }

  td {
    padding: 1.6rem 0 0.4rem 0;

    @include mixins.medium {
      padding-top: 3.2rem;
    }
  }

  td:last-child {
    text-align: right;
  }

  p {
    margin: 0;
    font-size: 1.4rem;

    @include mixins.medium {
      font-size: 1.8rem;
    }
  }
}

.fundFinancialHistory__table--annual {
  th:first-child {
    visibility: unset;
  }

  th:first-child,
  td:first-child {
    width: unset;
  }

  td {
    text-align: center;
  }

  th:last-child,
  td:last-child {
    text-align: center;
    @include mixins.medium {
      width: unset;
    }
  }
}

.fundFinancialHistory__value {
  @extend .t-paragraph--small;

  color: colors.$color-black;

  @include mixins.medium {
    font-size: 1.8rem;
    font-weight: 400;
  }
}

.fundFinancialHistory__table + .highlight {
  margin-top: 4rem;
}
