@use 'sass:map';
@use '../../utils/variables';
@use '../../utils/colors';

.totalPrivacy {
  position: relative;
  margin: 1rem 1rem 1rem 0;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.totalPrivacyDisable__modal {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2rem 2rem;
  text-align: center;
  max-width: 30rem;
  min-width: 16rem;
  border-radius: 12px;
  background: colors.$color-white;
  border: solid 2px colors.$color-gray-2;
  box-shadow: map-get(variables.$elevation-shadows, 'modal');

  z-index: map.get(variables.$z-index, modal);
}
