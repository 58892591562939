@use 'sass:map';
@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.profileHeading {
  padding: 0.8rem;

  text-align: center;

  @include mixins.medium {
    text-align: left;
  }
}

.profileHeading + .profileSection {
  margin-top: 6.4rem;

  @include mixins.medium {
    margin-top: 10.4rem;
  }
}

.profileHeader__avatarWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.8rem;

  @include mixins.medium {
    flex-direction: row;
    justify-content: space-between;
    gap: 2.4rem;
  }

  &--public {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.6rem;

    @include mixins.medium {
      flex-direction: row;
      justify-content: unset;
    }
  }
}

.editableAvatar__button {
  position: relative;
  min-width: unset;
  background: none;
  padding: 0;

  .constituentAvatar--extraLarge {
    position: relative; // prevents Safari from cutting off avatar's box shadow ¯\_(ツ)_/¯
  }
}

.editableAvatar__camera {
  position: absolute;
  bottom: 0.6rem;
  right: 0.6rem;

  @include mixins.medium {
    bottom: 0.8rem;
    right: 2.4rem;
  }
}

.photoUploadModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: map-get(variables.$elevation-shadows, 'modal');
  border-radius: 2rem;
  background: colors.$color-white;
  padding: 4rem 6rem;

  z-index: map.get(variables.$z-index, 'modal');

  @include mixins.medium {
    padding: 8rem 9.6rem;
  }
}

.fileUpload + .submitButton,
.photoUploadModal__form + .photoUploadModal__cancel {
  margin-top: 2rem;
}

.profileHeader__viewPublicBtn {
  display: inline-block;
  flex-shrink: 1;
}

.profileHeader__name,
.profileHeader__location,
.profileHeader__employer {
  margin: 0;
}

.profileHeader__avatarWrapper + .deceasedLabel,
.profileHeader__avatarWrapper + .profileHeader__name,
.totalPrivacy + .profileHeader__name,
.totalPrivacy + .deceasedLabel {
  margin-top: 1.6rem;

  @include mixins.large {
    margin-top: 3.2rem;
  }
}

.profileHeader__name + .profileHeader__location,
.profileHeader__location + .profileHeader__employer {
  margin-top: 0.8rem;
}

.constituentAvatar + .myNetworkStatus,
.constituentAvatar + .profileHeader__viewPublicBtn,
.editableAvatar__button + .profileHeader__viewPublicBtn {
  margin-top: 1.6rem;

  @include mixins.medium {
    margin-top: 0;
  }
}

.deceasedLabel {
  display: inline-block;
  margin-bottom: 0;
  border-radius: 0.4rem;
  background-color: colors.$color-gray-2;
  padding: 0rem 0.5rem;
}

.deceasedLabel + .profileHeader__name {
  margin-top: 0.5rem;
}
