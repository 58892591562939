@use '../utils/mixins';
@use '../utils/variables';

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navBar + .app__main {
  margin-top: 1.6rem;

  @include mixins.medium {
    margin-top: 3.2rem;
  }
}

// The public nav bar has a fixed position, so we need to add a margin to the main content to avoid overlapping
.publicNavBar + .app__main {
  margin-top: 7.8rem;

  @include mixins.medium {
    margin-top: 11.5rem;
  }
}

.app__main {
  flex: 1;
}

.mainContainer {
  max-width: 900px;
  display: block;
  margin: 0px 8px;
  background-color: transparent;

  @include mixins.large {
    margin: auto;
    height: 100%;
  }
}

.pageTitle {
  margin-top: 5rem;

  font-weight: 700;
  font-size: 3rem;
  line-height: 1.3;
  font-family: variables.$font-stack-default;

  @include mixins.medium {
    font-size: 5rem;
  }

  & + * {
    margin-top: 2rem;
  }
}
