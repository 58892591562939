@use 'sass:map';

@use '../../utils/variables';
@use '../../utils/colors';
@use './boxField';

.boxField {
  .select__label--shifted {
    @extend %boxField__label--shifted;
  }

  .reactSelect__control {
    border-radius: 10px;
    border: solid boxField.$border-width colors.$color-gray-3;
    background: colors.$color-white;
    padding: boxField.$vertical-padding 0.8rem boxField.$vertical-padding
      boxField.$horizontal-padding;

    &:hover {
      cursor: pointer;
    }
  }

  .reactSelect__control--is-focused {
    border-color: colors.$color-black;

    &:hover {
      border-color: colors.$color-black;
    }
  }

  .reactSelect__value-container {
    min-width: 4rem;
    padding: 0;
    overflow: visible;
  }

  .reactSelect__value-container--is-multi {
    gap: 0.8rem;
  }

  .reactSelect__single-value {
    font: 500 1.8rem / boxField.$line-height variables.$font-stack-default;
    color: colors.$color-black;
  }

  .reactSelect__placeholder {
    margin: 0;

    font: 500 1.8rem / 1 variables.$font-stack-default;
    color: colors.$color-gray-4;

    transition: 0.2s ease-in-out;
  }

  .reactSelect__input-container {
    font: 500 1.8rem / boxField.$line-height variables.$font-stack-default;
    color: colors.$color-black;
  }

  .reactSelect__input {
    border-radius: 0; // This prevents .reactSelect__control's border radius from being inherited
  }

  .reactSelect__input > input:focus {
    outline: none;
    box-shadow: none;
  }

  .reactSelect__multi-value {
    margin: 0;
    border-radius: 4px;
    background-color: colors.$color-gray-2;
    padding: 0.2rem 0.8rem;
  }

  .reactSelect__multi-value__label {
    font: 400 1.4rem / 1.5 variables.$font-stack-default;
    color: colors.$color-black;
  }

  .reactSelect__multi-value__remove {
    transition: 0.2s ease-in-out;
    &:hover {
      background-color: transparent;
      color: colors.$color-alert;
    }
  }

  .reactSelect__indicators {
    flex: 0;
    max-height: 1.8rem;
  }

  .reactSelect__indicator-separator {
    display: none;
    margin: 0;
  }

  .reactSelect__menu {
    margin: 0;
  }

  .reactSelect__option {
    padding: 2rem 2.5rem;

    font: 500 1.8rem / boxField.$line-height variables.$font-stack-default;
    color: colors.$color-black;

    &:hover {
      cursor: pointer;
    }
  }

  .reactSelect__option--is-focused {
    background: colors.$color-mist;
  }

  .reactSelect__option--is-selected {
    background: colors.$color-black;

    color: colors.$color-white;
  }
}

.boxField--select {
  label {
    pointer-events: none;
  }

  input {
    min-height: unset;
  }
}

.boxField--select--error {
  .reactSelect__control {
    box-shadow: map.get(variables.$elevation-shadows, 'alert');
    border-color: colors.$color-alert;
  }

  .reactSelect__control--is-focused {
    border-color: colors.$color-alert;
  }

  .select__label {
    color: colors.$color-alert;
  }

  .reactSelect__input input {
    box-shadow: none;
  }
}
