@use '../../utils/mixins';
@use '../../utils/colors';

.eventDetailsPage {
  .pageLayout__content--background {
    border: solid 1px colors.$color-white;
    background-image: url('../../../images/event-banner.svg');
    background-repeat: no-repeat;
    padding-top: 7.2rem;
  }

  .eventDateBlock {
    min-width: unset;
    display: inline-block;
  }
}

.eventDetailsPageSkeleton {
  display: grid;
  gap: 2.4rem;

  .eventDateBlock {
    width: 8rem;
    height: 5.6rem;
  }
}

.eventDetailsPage__errorMsg {
  margin-top: 8rem;
}

.eventDetailsPage__breadcrumbs {
  display: flex;
  gap: 0.8rem;
  align-items: center;
}

.eventDetailsPage__breadcrumbsSkeleton {
  display: flex;
  gap: 0.8rem;
  align-items: center;
}

.eventDetailsPage__signInOrContinueAsGuestModalButtons {
  display: flex;
  gap: 1.2rem;
  flex-direction: column;
  align-items: center;
}

.eventDetails__sectionWithLine {
  margin-bottom: 4rem;
  border-bottom: solid 1px colors.$color-gray-3;
  padding-bottom: 4rem;
}

.eventDateBlock + .eventDetails__eventTitle {
  margin-top: 2.4rem;
}

.eventDetails__titleWrapper {
  display: grid;
  gap: 2.4rem;
  margin: 2.4rem 0;

  @include mixins.medium {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.eventDetails__eventTitle {
  margin: 0;
}

.eventDetails__titleWrapper + .eventDetails__time,
.eventDetails__time + .eventDetails__location {
  margin-top: 2rem;
}

.eventDetails__time,
.eventDetails__location p,
.eventDetails__descriptionHeader,
.eventDetails__description {
  margin: 0;
}

.eventDetails__descriptionHeader + .eventDetails__description {
  margin-top: 1.6rem;
}

.eventDetails__description + .eventDetails__contact,
.eventDetails__contact + .eventAttendeesList {
  margin-top: 4rem;
}

.eventDetails__contact {
  p {
    margin: 0;
  }

  p + p {
    margin-top: 1.6rem;
  }
}

.myParty__list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li + li {
    margin-top: 2.4rem;
  }
}

.eventAttendeesList__titleWrapper,
.eventAttendeesList__wrapper {
  display: flex;
  align-items: center;
  gap: 2.4rem;
}

.eventAttendeesList__title {
  margin: 0;
}

.eventAttendeesList__titleWrapper + .eventAttendeesList__wrapper {
  margin-top: 0.8rem;
}

.eventDetails___attendeesList {
  margin: 0;
  padding: 0 2.4rem 0 0;

  list-style-type: none;

  &--collapsed {
    display: flex;
  }

  &--expanded {
    max-height: 30.4rem;
    overflow-y: auto;
  }

  li:first-child .eventDetails___attendeeAvatar {
    margin-left: 0;
  }
}

.eventDetails___attendeeAvatar {
  margin-left: -0.4rem;
}

.eventAttendeesList__avatarName {
  display: flex;
  align-items: center;
  gap: 1.6rem;

  & + & {
    margin-top: 2.4rem;
  }
}

.eventAttendeesList__attendeeName {
  margin: 0;
}

.eventAttendeesList + .eventDetails___addToCal,
.eventDetails___addToCal + .eventDetails__actionButtons,
.eventDetails__contact + .eventDetails__actionButtons,
.eventAttendeesList + .eventDetails__actionButtons,
.eventAttendeesList__wrapper + .eventAttendeesList__disclaimer {
  margin-top: 4rem;
}

.eventAttendeesList + .eventDetails___addToCal,
.eventDetails__contact + .eventDetails___addToCal {
  margin-top: 4rem;
}

.eventDetails___addToCal {
  max-width: fit-content;
  white-space: nowrap;
}

.eventDetails__actionButtons {
  display: flex;
  flex-direction: column-reverse;
  gap: 2.4rem;

  @include mixins.medium {
    flex-direction: row;
    justify-content: space-between;
  }

  &--subEvent {
    @include mixins.medium {
      justify-content: center;
    }
  }
}

.eventDetailsFooter__right {
  @include mixins.medium {
    margin-left: auto;
  }
}

.eventDetailsFooter__registerButton,
.eventDetailsFooter__cancelRegistrationButton {
  width: 100%;

  @include mixins.medium {
    width: auto;
  }
}

.individualEventDetails__rsvpWrapper {
  display: flex;
  gap: 1.6rem;
  justify-content: space-between;
}

.individualEventDetails__back {
  display: inline-block;
}

.confirmRsvpNoModal__buttons {
  display: flex;
  gap: 1.6rem;
  flex-direction: column;

  @include mixins.medium {
    flex-direction: row;
    justify-content: center;
  }
}

.confirmRsvpNoModal__buttons + .confirmRsvpNoModal__notYouButton {
  margin-top: 2.4rem;
}

.eventDetails__regRequiresSignInLink {
  font-size: 1.8rem;
}

.eventDetails__inviteOnlyImage {
  display: block;
  margin: 0 auto;
  max-width: 20rem;
}
