@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.advancedSearch__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.advancedSearch__headerWrapper {
  flex: 1;
  justify-content: space-between;
  align-items: baseline;
}

.advancedSearch__header {
  flex: 1;
  margin: 0;
  padding: 2.4rem 0;

  color: colors.$color-gray-4;
}

.advancedSearch__toggle {
  flex: 0;
  padding: 2.4rem 2.4rem 2.4rem 1.6rem;
}

.advancedSearch__mainContainer {
  position: relative;
  flex-basis: 100%;
  border-top: solid 2px colors.$color-gray-2;
  padding: 2.4rem 3.2rem 4rem 3.2rem;

  .form__overallError {
    max-width: unset;
  }
}

.advancedSearch__basicFieldsWrap {
  @include mixins.medium {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2.4rem;
  }
}

.advancedSearch__employerField--expanded {
  grid-column: 1 / span 2;
  max-width: initial;
}

.advancedSearch__basicFieldsWrap {
  .boxField + .boxField {
    @include mixins.medium {
      margin: 0;
    }
  }
}

.advancedSearch__moreButton {
  @include mixins.medium {
    position: absolute;
    bottom: 7.2rem;
  }

  &--expanded {
    @extend .advancedSearch__moreButton;
    position: static;
  }
}

.advancedSearch__basicFieldsWrap + .advancedSearch__moreButton,
.advancedSearch__moreButton + .advancedSearch__formButtonWrapper,
.advancedSearch__advFieldsWrap + .advancedSearch__formButtonWrapper {
  margin-top: 4rem;
}

.advancedSearch__moreButton + .advancedSearch__advFieldsWrap {
  margin-top: 3.2rem;
}

.advancedSearch__advFieldsWrap {
  .boxField {
    max-width: unset;
  }
}

.advancedSearch__formButtonWrapper {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-top: 2rem;

  @include mixins.medium {
    flex-direction: row-reverse;
    justify-content: right;
  }
}

.advancedSearchResultsListSkeleton {
  padding: 0;
}

.advancedSearchResultListItemSkeleton {
  display: flex;
  gap: 1rem;
}

.advancedSearchResultListItemSkeleton + .advancedSearchResultListItemSkeleton {
  margin-top: 3rem;
}

.advancedSearchResultListItemSkeleton__details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
