@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';
@use '../../base/buttons';

.radio + .radio {
  margin-top: 3.2rem;
}

.radio--disabled {
  pointer-events: none;
}

.radioInput {
  @include mixins.showFocusOnLabel('.radioLabel');
}

.radioInput:checked + .radioLabel {
  font-weight: 500;

  // Show custom 'checked' radio button dot
  &::after {
    opacity: 1;
  }
}

.radioInput:disabled + .radioLabel {
  color: colors.$color-gray-4;

  &::before {
    border: 2px solid colors.$color-gray-3;
    background-color: colors.$color-gray-3;
  }
}

.radioLabel {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0;

  color: colors.$color-gray-5;

  &:hover {
    cursor: pointer;
  }

  // Create custom radio button circle
  &::before {
    content: '';
    flex-shrink: 0;
    display: block;
    width: 2.8rem;
    height: 2.8rem;
    margin-right: 1rem;
    border-radius: 100%;
    border: 2px solid colors.$color-gray-5;
    background: colors.$color-white;
  }

  // Create custom 'checked' radio button dot
  &::after {
    content: '';
    flex-shrink: 0;
    display: block;
    position: absolute;
    // center vertically for Safari
    top: 50%;
    transform: translateY(-50%);
    left: 0.5rem;

    width: 2.2rem;
    height: 2.2rem;
    border-radius: 100%;
    background: colors.$color-gray-5;

    opacity: 0;
    transition: 0.25s opacity;
  }
}

.radio--uiButtons {
  text-align: center;

  .radioInput:checked + .radioLabel {
    min-width: 18rem;
    background: colors.$color-gray-2;
    border-radius: 10px;
    border: none;
  }

  .radioLabel {
    display: inline-block;
    margin: 0 auto;

    font: 400 1.8rem/1.5 variables.$font-stack-default;
    color: colors.$color-black;
    text-align: center;
    padding: 1.3rem;

    &::before,
    &::after {
      display: none;
    }
  }
}

.radio--uiButtons + .radio--uiButtons {
  margin-top: 1.5rem;
}
