@use '../utils/mixins';
@use '../utils/colors';

.skeletonCircle {
  border-radius: 50%;
  background: colors.$color-gray-2;

  @include mixins.loadingShimmerAnimation;

  &--small {
    width: 4.5rem;
    height: 4.5rem;
  }

  &--large {
    width: 10.9rem;
    height: 10.9rem;
  }

  &--extraLarge {
    width: 13.6rem;
    height: 13.6rem;

    @include mixins.medium {
      width: 16.8rem;
      height: 16.8rem;
    }
  }
}
