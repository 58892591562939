@use '../utils/variables';
@use '../utils/colors';
@use '../utils/mixins';

h1,
.t-header1 {
  font: 700 4.5rem/1.2 variables.$font-stack-default;
  color: colors.$color-black;

  @include mixins.medium {
    font-size: 5rem;
  }
}

h2,
.t-header2 {
  font: 500 3.2rem/1.2 variables.$font-stack-default;
  color: colors.$color-black;

  @include mixins.medium {
    font-size: 3.8rem;
  }
}

h3,
.t-header3 {
  font: 500 3rem/1.2 variables.$font-stack-default;
  color: colors.$color-gray-4;
}

h4,
.t-header4 {
  font: 400 2.4rem/1.2 variables.$font-stack-default;
  color: colors.$color-black;
}

h5,
.t-header5 {
  font: 500 1.2rem/1.2 variables.$font-stack-default;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: colors.$color-black;
}

.t-paragraph--large {
  font: 500 1.8rem/1.6 variables.$font-stack-default;
  color: colors.$color-black;

  @include mixins.medium {
    font-size: 2.4rem;
  }
}

.t-paragraph--largeIntro {
  font: 400 1.8rem/1.2 variables.$font-stack-default;
  color: colors.$color-gray-2;

  @include mixins.medium {
    font-size: 2.4rem;
  }
}

.t-paragraph--bold {
  font: 700 1.8rem/1.2 variables.$font-stack-default;
  color: colors.$color-black;
}

.t-paragraph--medium {
  font: 500 1.8rem/1.5 variables.$font-stack-default;
  color: colors.$color-black;
}

p,
li,
.t-paragraph {
  max-width: 62rem;
  font: 400 1.8rem/1.6 variables.$font-stack-default;
  color: colors.$color-black;
}

.t-paragraph--small {
  font: 500 1.4rem/1.5 variables.$font-stack-default;
  color: colors.$color-gray-4;
}

.t-label {
  font: 500 1.6rem/1.2 variables.$font-stack-default;
  color: colors.$color-gray-5;

  @include mixins.medium {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
}

.t-dates {
  font: 500 1.6rem/1.2 variables.$font-stack-default;
  color: colors.$color-gray-5;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

strong {
  font-weight: 700;
}

code {
  display: block;
  padding: 1.5rem 0;

  font: 400 1.8rem/1.2 'Courier New', monospace;
}
