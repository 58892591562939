@use '../../../utils/mixins';
@use '../../../utils/variables';
@use '../../../utils/colors';

.myGiving__dividerTitleBlock + .pledges__contentWrapper,
.pledges__contentWrapper + .myGiving__dividerTitleBlock {
  margin-top: 2rem;

  @include mixins.large {
    margin-top: 4rem;
  }
}

.pledges__contentWrapper {
  padding: 0 1rem 1rem 1rem;
  text-align: center;

  @include mixins.large {
    padding: 0 0 4rem 0;
  }
}

.pledges__managePaymentButton {
  &:link,
  &:visited {
    color: colors.$color-white;
  }
}

.pledges__managePaymentButton + .pledgeList {
  margin-top: 3rem;

  @include mixins.large {
    margin-top: 6rem;
  }
}

.pledgeList {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @include mixins.large {
    margin: 0 auto;
    width: 75%;
  }
}

.pledge {
  text-align: left;
}

.pledge + .pledge {
  margin-top: 1.6rem;
}

.pledge__title {
  @include mixins.medium {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.pledge__nextInstallment {
  font-family: variables.$font-stack-default;
  line-height: 1.5;
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0.02em;

  @include mixins.medium {
    font-weight: 700;
    font-size: 1.8rem;
  }
}

.pledge__content {
  padding: 2rem 1rem;
}

.pledge__initialAmountWrapper,
.pledge__balanceWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pledge__initialAmountWrapper + .pledge__balanceWrapper,
.pledge__balanceWrapper + .pledge__pending {
  margin-top: 1rem;
}

.pledge__initialAmountLabel {
  font: 700 1.4rem/1.5 variables.$font-stack-default;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.pledge__balanceWrapper,
.pledge__initialAmount {
  font: 900 1.4rem/1.5 variables.$font-stack-default;
  text-transform: uppercase;
  letter-spacing: 0.02em;

  @include mixins.medium {
    font-size: 1.8rem;
  }
}

.pledge__pending {
  font: 400 1.4rem/1.5 variables.$font-stack-default;
}
