@use 'variables';
@use 'colors';

// Foundation breakpoint
@mixin medium {
  @media screen and (min-width: 640px) {
    @content;
  }
}

// Foundation breakpoint
@mixin large {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

// Custom breakpoint
@mixin extraLarge {
  @media screen and (min-width: 1366px) {
    @content;
  }
}

@mixin focus($focusColor: colors.$color-new-gold) {
  // For Safari and IE
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px $focusColor;
  }

  // For Chrome and Firefox
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 3px $focusColor;
  }

  // Hide outlines for mouse users on Chrome and Firefox
  &:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
  }
}

@mixin showFocusOnLabel($labelClass) {
  // For Safari and IE
  &:focus + #{$labelClass} {
    outline: none;
    box-shadow: 0 0 0 3px colors.$color-new-gold;
  }

  // For Chrome and Firefox
  &:focus-visible + #{$labelClass} {
    outline: none;
    box-shadow: 0 0 0 3px colors.$color-new-gold;
  }

  // Hide outlines for mouse users on Chrome and Firefox
  &:focus:not(:focus-visible) + #{$labelClass} {
    outline: none;
  }
}

@mixin loadingShimmerAnimation() {
  background-image: linear-gradient(
    to right,
    colors.$color-gray-1 0%,
    rgba(colors.$color-gray-3, 0.2) 20%,
    colors.$color-gray-1 40%,
    colors.$color-gray-1 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 800px;
  animation: loadingShimmer linear forwards 1.5s infinite;
}

@keyframes loadingShimmer {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: 600px 0;
  }
}

@mixin loadingShimmerAnimationDark() {
  background-image: linear-gradient(
    to right,
    colors.$color-black 0%,
    rgba(colors.$color-gray-3, 0.2) 20%,
    colors.$color-black 40%,
    colors.$color-black 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 800px;
  animation: loadingShimmer linear forwards 1.5s infinite;
}

@keyframes loadingShimmer {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: 600px 0;
  }
}
