@use 'sass:map';

@use '../../utils/colors';
@use '../../utils/mixins';
@use '../../utils/variables';

.reviewRegistration__removeEventOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(colors.$color-midnight, 0.5);
  padding: 1.6rem;
}

.reviewRegistration__removeEventModal {
  position: relative;
  box-shadow: map.get(variables.$elevation-shadows, 'container');
  border-radius: 10px;
  background: colors.$color-white;
  padding: 7.2rem 4rem 3.2rem 4rem;

  @include mixins.medium {
    max-width: 80%;
  }
}

.reviewRegistration__removeEventModal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reviewRegistration__removeEventModalCloseButton {
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
}

.reviewRegistration__removeEventModalText {
  margin: 0;

  text-align: center;
}

.reviewRegistration__removeEventModalText
  + .reviewRegistration__removeEventModalButtons {
  margin-top: 3.2rem;
}

.reviewRegistration__removeEventModalButtons {
  display: flex;
  flex-direction: column-reverse;
  gap: 2.4rem;

  @include mixins.medium {
    flex-direction: row;
  }
}
