@use '../../utils/colors';
@use '../../utils/mixins';

.multiLevelEventCard {
  max-width: unset;
  border-radius: 10px;
  border: solid 1px colors.$color-gray-2;
  background-color: colors.$color-gray-1;
  padding: 1.6rem;

  .eventCard {
    border: solid 1px colors.$color-gray-3;
    background-color: colors.$color-white;
    padding: 1.6rem;
  }
}

.multiLevelEventCard__header {
  @include mixins.medium {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }
}

.eventDateBlock + .multiLevelEventCard__title,
.multiLevelEventCard__title + .multiLevelEventCard__register {
  margin-top: 3.2rem;

  @include mixins.medium {
    margin-top: 0;
  }
}

.multiLevelEventCard__header + .multiLevelEventCard__subEventsWrapper {
  margin-top: 3.2rem;

  @include mixins.medium {
    margin-top: 1.6rem;
  }
}

.multiLevelEventCard__subEventsWrapper {
  @include mixins.medium {
    display: flex;
  }

  &::before {
    @include mixins.medium {
      content: '';
      display: block;
      margin: 0 1.6rem 0 0;
      border-radius: 20px;
      background-color: colors.$color-gray-3;
      width: 4px;
    }
  }
}

.multiLevelEventCard__subEvents {
  flex: 1;
  margin: 0;
  padding: 0;

  list-style-type: none;

  .eventCard__separatorWrapper {
    &::before {
      display: none;
    }
  }
}

.multiLevelEventCard__title {
  display: block;

  color: colors.$color-black;

  &:visited {
    color: colors.$color-black;
  }
}

.multiLevelEventCard__register {
  display: block;
  margin-left: auto;
  min-width: unset;
}

.multiLevelEventCard + .multiLevelEventCard,
.multiLevelEventCard + .eventCard,
.eventCard + .multiLevelEventCard,
.multiLevelEventCard__subEventsGrouping + .eventCard,
.eventCard + .multiLevelEventCard__subEventsGrouping {
  margin-top: 3.2rem;
}
