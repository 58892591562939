@use '../../../utils/mixins';
@use '../../../utils/variables';
@use '../../../utils/colors';

.record {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.recordLabel {
  margin: 0;
  border-radius: 12px;
  border: solid 1px colors.$color-gray-2;
  background: colors.$color-gray-1;
  padding: 1.3rem 1.9rem;

  text-align: right;
  white-space: nowrap;

  @include mixins.medium {
    border: none;
    background: none;
    padding: 0;
    white-space: normal;
  }
}

.recordLabel + .viewMore {
  margin-left: 2.4rem;

  @include mixins.medium {
    margin-left: 0;
    margin-top: 0.8rem;
  }
}

.formerSectionLabel {
  font-weight: bold;
  font-family: variables.$font-stack-default;
  padding: 2.5rem 0 1rem 0;
  letter-spacing: 0.01rem;
  font-size: 1.5rem;
  margin: 0;
  display: block;

  @include mixins.large {
    margin: 0 0 1rem 0;
  }
}

.profileRecord__content + .profileRecord__content,
.profileRecord__content + .newRecordForm {
  margin-top: 2rem;
}

.profileRecord + .Collapsible,
.profileRecord__content + .Collapsible {
  .Collapsible__trigger {
    display: block;
    transition: margin 0.4s;
  }
  .Collapsible__trigger.is-open {
    margin-top: 2rem;
  }

  .Collapsible__trigger.is-closed {
    margin-top: 0;
  }
}

.subRecord {
  margin: 0;
}

.record + .subRecord,
.record + .Collapsible,
.subRecord + .record__dateRange {
  margin-top: 1.6rem;
}

.record + .record__editSpouseButton {
  margin-top: 2.4rem;
}

.recordMiniLabel {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin: 0;

  color: colors.$color-slate;
}

.recordMiniLabel + .record {
  margin-top: 0.8rem;
}

.viewMore {
  flex: 0;

  white-space: nowrap;
}

.record__employerEyeWrapper {
  display: flex;
  align-items: center;
}
