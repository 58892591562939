@use 'sass:map';
@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.employmentGrid {
  position: relative;
}

// Employment Update Reminder
.employmentUpdateReminder {
  position: absolute;
  top: 100%;
  width: 30rem;
  box-shadow: map-get(variables.$elevation-shadows, 'modal');
  border-top-right-radius: 30px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background: colors.$color-white;
  padding: 2.5rem;
  z-index: map.get(variables.$z-index, modal);

  @include mixins.medium {
    left: 7rem;
  }

  @include mixins.large {
    top: 0;
    left: -15rem;
    border-top-right-radius: 0px;
    border-top-left-radius: 30px;
  }
}

.employmentUpdateReminder__bell {
  color: colors.$color-new-gold;
  font-size: 3rem;
}

.employmentUpdateReminder__mainText {
  font-family: variables.$font-stack-default;
  font-size: 1.6rem;
  font-weight: 700;
}

.employmentUpdateReminder__smText {
  font-family: variables.$font-stack-default;
  font-size: 1.4rem;
  font-weight: 700;
}

.employmentUpdateReminder__btns {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.employmentUpdateReminder__upToDateBtn {
  font-family: variables.$font-stack-default;
  font-size: 1.4rem;
  font-weight: 400;
}

.employmentUpdateReminder__smText + .employmentUpdateReminder__btns {
  margin-top: 4rem;
}

.employmentUpdateReminder__mainText + .employmentUpdateReminder__mainText,
.employmentUpdateReminder__mainText + .employmentUpdateReminder__smText {
  margin-top: 1rem;
}

.employmentUpdateReminder__bell + .employmentUpdateReminder__mainText,
.employmentUpdateReminder__updateBtn + .employmentUpdateReminder__upToDateBtn {
  margin-top: 2rem;
}
