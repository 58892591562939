@use '../utils/mixins';

.topHats {
  display: flex;
  align-items: center;
}

.topHats__iconWrapper {
  // Remove extra height
  line-height: 1;

  svg {
    width: 1.6rem;
    height: 1.6rem;

    @include mixins.medium {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  // Add space between hats
  & > span:not(:first-child) {
    margin-left: 0.3rem;
  }
}

.topHats__iconWrapper + .tooltip__wrapper {
  margin-left: 1rem;
}

.topHats__toolTipSkeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.topHats__tooltip {
  display: none;
  max-width: 28rem;
  left: calc(50% - 2.4rem);

  @include mixins.medium {
    max-width: unset;
    left: 50%;
  }
}

.topHats__tooltip--show {
  display: block;
}

.topHats__tooltipText {
  margin: 0;
}
