@use 'sass:map';
@use '../../../utils/mixins';
@use '../../../utils/variables';
@use '../../../utils/colors';

.myClaimedClassmates__content {
  position: relative;
  margin-top: 3px;
}

.myClaimedClassmates__options {
  display: block;
  position: absolute;
  top: 0;
  right: 2rem;
  box-shadow: map-get(variables.$elevation-shadows, 'modal');
  border-radius: 0.5rem;
  background: colors.$color-white;
  z-index: map.get(variables.$z-index, 'modal-optionsmenu');

  @include mixins.large {
    right: 4rem;
  }
}

.myClaimedClassmates__optionsToggle {
  min-width: 12rem;
  border-radius: 20px;
  padding: 1rem;
  font-weight: bold;
  display: block;
  font-size: 1.5rem;
  font-family: variables.$font-stack-default;

  &:hover {
    cursor: pointer;
  }
}

.myClaimedClassmates__optionsButtons {
  border: none;

  &:hover {
    filter: none;
  }
}

.myClaimedClassmates__optionsMenu {
  border-top: solid 2px colors.$color-gray-3;
  margin-left: 0;
}

.myClaimedClassmates__list {
  margin: 0;
  padding: 0;
  height: 300px;
  overflow-y: scroll;

  @include mixins.medium {
    height: 600px;
  }
}

.myClaimedClassmates__listItem {
  position: relative;
  display: flex;
  gap: 2rem;
  padding: 2rem;

  @include mixins.large {
    padding: 3.5rem;
  }

  &:first-child {
    padding-top: 5rem;
  }
}

.myClaimedClassmates__detailsWrapper {
  flex: 1;
}

.myClaimedClassmates__nameWrapper {
  display: flex;
  align-items: center;
}

.myClaimedClassmates__name {
  display: block;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.5;
  font-family: variables.$font-stack-default;

  @include mixins.large {
    font-size: 2rem;
  }
}

.myClaimedClassmates__detailsContent {
  position: relative;
  display: grid;
  grid-template-columns: 3rem 1fr;
  grid-row-gap: 1rem;
  margin-top: 15px;
  border-top: solid 1px colors.$color-gray-2;
  font-size: 1.4rem;
  font-family: variables.$font-stack-default;

  @include mixins.medium {
    border: none;
    padding: 0;
  }
}

.myClaimedClassmates__detailsTextWrap {
  display: flex;
  align-items: center;
}

.myClaimedClassmates__detailsText {
  display: block;
  line-height: 1.5;
  font-weight: 700;
  font-size: 1.4rem;
  font-family: variables.$font-stack-default;
}

.cityStateEmployer {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.5;
  font-family: variables.$font-stack-default;
}

.myClaimedClassmates__ellipsisWrapper {
  position: relative;
  flex: 0;
}

.claimedClassmates__none {
  margin: 0 auto;
  padding: 1.6rem;
  text-align: center;
}

.iStar {
  height: auto;
  width: 18px;
  margin-left: 10px;

  @include mixins.large {
    width: 25px;
    margin-left: 20px;
  }
}
