@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.checkout__sectionBox {
  border-radius: 15px;
  border: solid 2px colors.$color-gray-3;
  background: colors.$color-gray-1;
  padding: 2rem;

  @include mixins.medium {
    padding: 4.8rem 4rem;
  }
}

.checkout__pendingMessage {
  margin: 0 auto;
  max-width: 50rem;

  // @extend%textSans--22med;
  color: colors.$color-black;
}

.checkout__pendingMessage + .selectPaymentForm {
  margin-top: 2rem;

  @include mixins.medium {
    margin-top: 6rem;
  }
}

.checkout__sectionBox + .error {
  margin-top: 1rem;
}

.checkout__sectionBox + .checkout__sectionBox,
.error + .checkout__sectionBox {
  margin-top: 3rem;
}

.checkout__cancelContinue {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;

  @include mixins.medium {
    margin-top: 5.6rem;

    flex-direction: row-reverse;
    justify-content: right;
  }
}

.checkout__continue + .checkout__cancel {
  margin-top: 2.4rem;

  @include mixins.medium {
    margin-top: 0;
    margin-right: 4rem;
  }
}

.checkout__cancel {
  text-align: center;
}
