@use '../../utils/mixins';
@use '../../utils/variables';

.searchResultContainer {
  box-shadow: map-get(variables.$elevation-shadows, 'accordion');
  background-color: white;
  padding: 1rem;
  text-align: left;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.advancedSearch__resultsHeader {
  margin: 0;
}

.advancedSearch__resultsWrapper {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.advancedSearch__backBtn + .searchResultsHeader,
.searchResultsHeader + .sortModalContainer {
  margin-left: 1.8rem;
}

.advancedSearch__resultsList {
  // required for <AutoSizer>
  height: 400px;

  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important; // prevents last search result's top hat tool tip from getting cut off
  }
}

.searchItem {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 1.6rem 0;

  text-align: left;

  @include mixins.medium {
    align-items: center;
  }
}

.overflow {
  overflow: hidden;
}

.searchResults__modalTrigger {
  &:hover {
    cursor: pointer;
  }
}

.searchResults__avatar {
  flex-shrink: 0;
}

.searchResults__modalTrigger + .searchResults__userDetails,
.searchResults__avatar + .searchResults__userDetails {
  margin-left: 1.6rem;
}

.searchResults__userName {
  text-align: left;
  font-family: variables.$font-stack-default;
  font-size: 1.6rem;
  line-height: 2.3rem;
  font-weight: 400;

  @include mixins.large {
    font-size: 1.9rem;
  }

  &--matches {
    font-weight: 700;
  }
}

.searchResults__userName + .searchResults__userInfo {
  margin-top: 0.5rem;
}

.searchResults__userInfo {
  font-size: 1.5rem;
  font-family: variables.$font-stack-default;
  font-weight: 500;
}

.searchResults__userInfo + .searchResults__topHats {
  margin-top: 1rem;
}

.cityStateEmployerPart {
  display: block; // separate lines on mobile

  @include mixins.large {
    display: inline-block; // same line on desktop
  }
}

.cityStateEmployerPart + .cityStateEmployerPart {
  // on desktop, add space and the pipe character between parts
  @include mixins.large {
    &::before {
      content: '|';
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}

.advancedSearch__noResults {
  font: 400 2rem/1.5 variables.$font-stack-default;
  text-align: center;
}
