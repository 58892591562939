@use 'sass:meta';
@use '../../../utils/variables';

.employmentCollapsible {
  margin-top: 2.4rem;
}

.employmentCollapsible--transition {
  transition: height 0.5s ease-in-out;
}

@include meta.load-css('employerGroup');
@include meta.load-css('jobRecord');

@include meta.load-css('editEmployerGroupForm');
