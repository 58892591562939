@use '../utils/mixins';
@use '../utils/variables';
@use '../utils/colors';

.helpHeading {
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 1.8rem;
  font-family: variables.$font-stack-default;
}

.help__formContainer {
  @include mixins.medium {
    max-width: 40rem;
  }
}

.help__instructions + .help__form {
  margin-top: 4rem;
}

.help__form {
  .boxField {
    max-width: unset;
    width: 100%;
    min-height: 6rem;
  }
}

.boxField + .googleReCaptcha {
  margin-top: 3.2rem;
}

.authHelpForm__fieldsWrapper + .authHelpForm__submitBtn {
  margin-top: 3rem;
}

.authHelpForm__submitBtn {
  margin: 0 auto;
}

.help__feliciaHelpLogo {
  display: none;
  @include mixins.medium {
    display: block;
    max-height: 56rem;
    width: 100%;
    object-fit: contain;
  }
}
