@use 'sass:map';

@use '../utils/mixins';
@use '../utils/variables';
@use '../utils/colors';

.downtime__wrapper {
  width: 100vw;
  height: 100vh;
  padding: 7rem 1.6rem 3.2rem 1.6rem;
  background-position: center 7rem;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: -7.5rem; // offset the normal margin-top so the downtime messaging fits in the viewport

  @include mixins.medium {
    background-position: center 11rem;
    padding-top: 11rem;
    margin-top: -11.5rem; // offset the normal margin-top so the downtime messaging fits in the viewport
  }
}

.downtime__container {
  display: flex;
  align-items: center;
  flex-direction: column;

  text-align: center;
}

.downtime__message {
  margin: 0;
  box-shadow: map-get(variables.$elevation-shadows, 'container');
  border-radius: 20px;
  background-color: colors.$color-white;
}

.downtime__message + .downtime__message {
  margin-top: 3.2rem;
}

h1.downtime__message {
  max-width: 128rem;
  padding: 1.6rem 3.2rem;

  @include mixins.large {
    padding: 3.2rem 6.4rem;
  }

  @include mixins.extraLarge {
    white-space: nowrap;
  }
}

h2.downtime__message {
  max-width: 82.8rem;
  padding: 1.6rem 2.4rem;

  @include mixins.large {
    padding: 2.4rem 8.8rem;
  }
}
