@use '../../utils/variables';
@use '../../utils/colors';
@use '../../base/typography';

legend {
  margin-bottom: 1.6rem;
}

label {
  @extend .t-paragraph;
}

// Spacing between fields
.boxField + .boxField,
.boxField + .dateRangeMMDD,
.boxField + .dateRange,
.dateRange + .boxField,
.boxField + .intlPhoneField,
.dateRange + .dateSelectMMDD,
.dateSelectMMDD + .dateRangeMMDD,
fieldset + .boxField,
.selectPaymentForm__radioWrapper--other + .boxField {
  margin-top: 3.2rem;
}

.boxField + .submitButton {
  margin-top: 3.2rem;
}

.form__overallError {
  max-width: 43.5rem;
  border: solid 2px colors.$color-alert;
  border-radius: 20px;
  padding: 1rem 2rem;

  color: colors.$color-alert;
}
