.additionalInfoForm__instructions {
  text-align: center;
}

.additionalInfoForm__instructions + .additionalInfoForm {
  margin-top: 6.4rem;
}

.additionalInfoForm {
  max-width: 40rem;
  margin: 0 auto;
}

.additionalInfoForm__fieldset {
  & + & {
    margin-top: 7.2rem;
  }
}
