@use '../../utils/colors';

.eventRegCheckpoint {
  text-align: center;
}

.eventRegCheckpoint__title {
  max-width: 80rem;
}

.eventRegCheckpoint__title + .eventRegCheckpoint__icon {
  margin-top: 5.6rem;
}

.eventRegCheckpoint__icon {
  font-size: 6.4rem;
  color: colors.$color-black;
}
