@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.ssnLastFour__legend {
  font-weight: 400;
  text-align: left;
  margin-bottom: 0;
}

.ssnLastFour__fieldContainer {
  display: flex;
  gap: 1.8rem;
  justify-content: flex-start;

  @include mixins.medium {
    position: relative;
  }

  .boxField {
    margin-top: 0;
  }
}

.ssnLastFour__legend + .ssnLastFour__fieldContainer {
  margin-top: 2rem;
}

input.ssnField__input {
  padding: 0;
  text-align: center;
  max-height: 4rem;
  @include mixins.medium {
    font-size: 3.2rem;
    max-width: 9.6rem;
    max-height: 6.4rem;
  }

  &--password {
    font-family: Verdana, sans-serif;
  }
}

.ssnLastFour + .validationFail {
  margin-top: 2rem;
}

.validationFail {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.validationFail__message {
  font: 700 1.6rem/1.5 variables.$font-stack-default;
  color: colors.$color-alert;
}

.validation__formContainer {
  margin-bottom: 3.2rem;
  @include mixins.medium {
    margin-bottom: 6rem;
    .boxField {
      max-width: 48rem;
    }
  }
}

.ssnLastFour + .googleReCaptcha,
.boxField + .googleReCaptcha {
  margin-top: 3.2rem;
}

.validation__typeButton {
  display: block;
  margin: 0;
  font-size: 1.8rem;
  font-weight: 400;
  color: colors.$color-black;
}

.validation__typeButton + .validation__typeButton {
  margin-top: 3.2rem;
}

.validation__otherOptionsContainer {
  margin-top: 4rem;
}

.validation__lockLogo {
  display: none;
  @include mixins.medium {
    display: block;
    margin-top: 2rem;
    max-height: 43rem;
    width: 100%;
    object-fit: contain;
  }
}
