@use 'sass:map';
@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.scholarships__bio {
  margin-left: auto;
  margin-right: auto;
  max-width: 32rem;
}

.scholarships__bioEdit {
  display: flex;
  justify-content: center;
}

.scholarships__editNote__submitBtn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 15rem;
}

.scholarships__missingBioStatement {
  max-width: 28.4rem;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: variables.$font-stack-default;
  font-size: 1.8rem;
  line-height: 155.7%;
}
