@use 'sass:map';
@use '../../../utils/variables';
@use '../../../utils/colors';

.copiedToClipboard {
  position: absolute;
  width: fit-content;
  white-space: nowrap;
  border-radius: 3rem;
  border: solid 1px colors.$color-success;
  background: colors.$color-white;
  padding: 1rem 1.5rem;

  color: colors.$color-success;

  z-index: map.get(variables.$z-index, modal);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;

  &--show {
    opacity: 1;
  }

  &--below {
    top: calc(100% + 2rem);
    right: 0;
  }

  &--centered {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.copiedToClipboard__icon {
  font-size: 1.8rem;
}

.copiedToClipboard__icon + .copiedToClipboard__text {
  margin-left: 1rem;
}

.copiedToClipboard__text {
  font: 700 1.5rem/1 variables.$font-stack-default;
}
