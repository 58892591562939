@use '../../utils/variables';
@use '../../utils/colors';

.loyalDeac__panelContent {
  padding: 3.2rem 2.4rem;
}

.loyalDeac__hearts {
  display: flex;
}

.loyalDeacHeart {
  position: relative;
}

.loyalDeacHeart + .loyalDeacHeart {
  margin-left: 0.5rem;
}

.loyalDeacHeart__letter {
  position: absolute;
  top: -0.5rem; // visually center letter on heart vertically
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 2.4rem;
  font-weight: 900;
  font-family: variables.$font-stack-default;
  line-height: 1;
  color: colors.$color-gray-5;

  &--filled {
    color: colors.$color-midnight;
  }
}

.loyalDeac__hearts + .loyalDeac__details {
  margin-top: 6.4rem;
}

.loyalDeac__details {
  border-radius: 10px;
  background: colors.$color-gray-1;
  padding: 2.4rem 2.4rem 5.6rem 2.4rem;
}

.loyalDeac__textHeader,
.loyalDeac__text {
  margin: 0;
}

.loyalDeac__textHeader + .loyalDeac__text,
.loyalDeac__text + .loyalDeac__text {
  margin-top: 2.4rem;
}
