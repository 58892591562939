@use '../../utils/mixins';

.eventRegistrationContainer {
  display: flex;
  flex-direction: column;
  padding: 2.4rem 1.6rem;

  @include mixins.medium {
    margin: 0 auto;
    max-width: 90rem;
    align-items: center;
    padding: 3.2rem;
  }
}

.eventRegistrationContainer__content {
  padding: 4rem 0;
}
