@use '../../utils/mixins';
@use '../../utils/colors';

.landingPage__content {
  flex: 0;
  display: flex;
  flex-direction: column;
  gap: 10rem;
  padding: 0 5rem;

  @include mixins.medium {
    flex-direction: row;
    gap: 5rem;
    justify-content: space-between;
  }

  @include mixins.extraLarge {
    padding: 0 15rem;
  }
}

.landingPage__contentItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landingPage__contentIcon {
  display: block;
  height: 2.5rem;
  width: 2.5rem;

  @include mixins.medium {
    margin-right: 3rem;
  }
}

.landingPage__contentIcon + .landingPage__contentText {
  margin-top: 1.5rem;
}

.landingPage__contentText {
  margin: 0;
  max-width: 35rem;

  text-align: center;
  color: colors.$color-white;

  @include mixins.medium {
    text-align: left;
  }
}
