// Base
$color-midnight: #1b1b1b;
$color-new-gold: #ffc629;
$color-slate: #607886;
$color-salmon: #f48181;
$color-mist: #eff0f3;
$color-sunrise: #fee296;

// Interactions
$color-success: #3e8362;
$color-alert: #a81508;

// Neutrals
$color-black: #2b2b2b;
$color-gray-5: #6b6b6b;
$color-gray-4: #757575;
$color-gray-3: #dedede;
$color-gray-2: #efefef;
$color-gray-1: #f7f7f7;
$color-white: #fff;
$color-ghost-white: #f6f7fa;

// This helps keep the style guide's colors up-to-date
// by allowing JS to import the actual colors.
// Ref: https://css-tricks.com/getting-javascript-to-talk-to-css-and-sass/#aa-sass-variables-and-javascript
:export {
  base: {
    midnight: $color-midnight;
    newgold: $color-new-gold;
    slate: $color-slate;
    salmon: $color-salmon;
    mist: $color-mist;
    sunrise: $color-sunrise;
  }

  interactions: {
    success: $color-success;
    alert: $color-alert;
  }

  neutrals: {
    black: $color-black;
    gray5: $color-gray-5;
    gray4: $color-gray-4;
    gray3: $color-gray-3;
    gray2: $color-gray-2;
    gray1: $color-gray-1;
    white: $color-white;
    ghostwhite: $color-ghost-white;
  }
}
