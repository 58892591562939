@use 'sass:map';

@use '../utils/variables';
@use '../utils/colors';
@use '../utils/mixins';

.styleGuide__nav {
  @include mixins.medium {
    display: flex;
    justify-content: space-evenly;
  }
}

.styleGuide__navLink {
  display: block;
}

.colorList {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.colorList__swatch {
  margin-bottom: 1rem;
  width: 12rem;
  height: 12rem;
}

.styleGuide__icons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 2rem;
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.styleGuide__icon {
  text-align: center;
}

.styleGuide__iconImage {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
}

.styleGuide__iconName {
  display: block;
}

.styleGuide__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.styleGuide__regularButtons {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.6rem;

  @include mixins.medium {
    grid-template-columns: auto 1fr 1fr 1fr;
  }
}

.styleGuide__iconOnlyButtons {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-gap: 1.6rem;
}

.styleGuide__skeletonCircles {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.styleGuide__loadingSection {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.styleGuide__elevations {
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.styleGuide__elevation {
  border-radius: 18px;
  max-width: 32rem;
  background: colors.$color-white;
  padding: 2.4rem;

  &--highlight {
    filter: drop-shadow(map.get(variables.$elevation-shadows, 'highlight'));
  }

  &--hover {
    filter: drop-shadow(map.get(variables.$elevation-shadows, 'hover'));
  }

  &--modal {
    filter: drop-shadow(map.get(variables.$elevation-shadows, 'modal'));
  }

  &--container {
    box-shadow: map.get(variables.$elevation-shadows, 'container');
  }

  &--accordion {
    filter: drop-shadow(map.get(variables.$elevation-shadows, 'accordion'));
  }

  &--alert {
    filter: drop-shadow(map.get(variables.$elevation-shadows, 'alert'));
  }
}

.styleGuide__elevation + .styleGuide__elevation {
  margin-top: 3.2rem;
}
