@use 'sass:map';
@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.myGiving__dividerTitleBlock,
.givingSkeleton__blackBar {
  position: relative;
  border-radius: 0.3rem;
  margin: 0 1rem;
  background: colors.$color-black;
  padding: 1rem;

  text-align: center;
}

.myGiving__helpRequest {
  position: absolute;
  top: 50%;
  right: 1rem;

  z-index: map.get(variables.$z-index, modal-optionsmenu);
  transform: translateY(-50%);
}

.myGiving__makeAGiftWrapper {
  text-align: center;
}

.myGiving__makeAGift {
  display: inline-block;
  margin: 2.4rem 0;

  &:link,
  &:visited {
    color: colors.$color-white;
  }
}

.myGiving__dividerTitle {
  margin: 0;

  color: colors.$color-gray-1;
}

.myGiving__dividerTitle,
.myGiving__summaryLabel {
  font: 500 1.8rem/1 variables.$font-stack-default;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.18rem;
}

.myGiving__summaryContent {
  padding: 3rem;
}

.myGiving__summaryLabel + .myGiving__summaryAmount,
.myGiving__thisFiscalYearWrapper + .myGiving__summaryAmount {
  margin-top: 1.5rem;
}

.myGiving__summaryAmount + .myGiving__thisFiscalYearWrapper {
  margin-top: 4rem;
}

.myGiving__summaryAmount {
  font: 700 2.4rem/1 variables.$font-stack-default;
  text-align: center;
}

.myGiving__thisFiscalYearWrapper {
  position: relative;
}

.myGiving__dividerTitle + .myGiving__givingHistoryContent {
  margin-top: 2rem;
}

.myGiving__givingHistoryContent {
  padding: 1rem 1rem 4rem 1rem;

  @include mixins.large {
    margin: auto;
    padding: 2rem 0rem 4rem 0rem;
    width: 75%;
  }
}

.myGiving__yearSection {
  & > .accordion__panel {
    padding: 0 1rem 2rem 1rem;
  }
}

.myGiving__year {
  font: 600 2.4rem/1 variables.$font-stack-default;
  letter-spacing: 0.08rem;
}

.myGiving__giftType {
  margin-top: 0.8rem;
  border-radius: 0.3rem;
  width: fit-content;
  background: colors.$color-gray-3;
  padding: 0.4rem 1.2rem;

  font: 700 1.2rem/1 variables.$font-stack-default;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: colors.$color-black;
}

.myGiving__rowWithLineAbove {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid 2px colors.$color-gray-2;
  padding: 1.6rem 0rem;
}

.myGiving__rollupTooltip {
  text-transform: none;
  letter-spacing: normal;
}

.myGiving__giftAmount {
  font: 500 1.4rem/1 variables.$font-stack-default;
}

.myGiving__giftTotalLabel {
  font: 700 1.4rem/1 variables.$font-stack-default;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.myGiving__giftTotalAmount {
  font: 700 1.4rem/1 variables.$font-stack-default;
}

.myGiving__giftLegalDonor {
  font: 400 1.2rem/1.5 variables.$font-stack-default;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.myGiving__giftSection + .myGiving__giftSection,
.myGiving__giftSection + .myGiving__yearTotal {
  margin-top: 1.6rem;

  @include mixins.large {
    margin-top: 2.4rem;
  }
}

.myGiving__yearTotal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1rem;

  font: 900 1.4rem/1 variables.$font-stack-default;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.myGiving__yearSection + .myGiving__loadMoreButton {
  margin-top: 3.2rem;
}

.givingSkeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding-bottom: 4rem;
}

.givingSkeleton__blackBar {
  margin-bottom: 0;
  height: 4rem;
  flex-shrink: 0;
  align-self: stretch;
}

// Giving Checkout page

p + .givingCheckoutForm {
  margin-top: 4rem;
}

.givingCheckoutForm__giftRow {
  display: flex;
  gap: 1.6rem;
  padding: 0 1.6rem;
}

.givingCheckoutForm__giftRowFields {
  flex: 1;

  @include mixins.medium {
    display: flex;
    // align-items: center;
    gap: 1.6rem;
  }

  .givingCheckoutForm__amount {
    @include mixins.medium {
      max-width: 17.6rem;
    }
  }

  .boxField--select {
    max-width: 45rem;
    flex: 1;

    @include mixins.medium {
      margin: 0;
    }
  }
}

.givingCheckoutForm__giftRow + .givingCheckoutForm__giftRow {
  margin-top: 4rem;
  border-top: solid 2px colors.$color-gray-2;
  padding-top: 4rem;

  @include mixins.medium {
    border: none;
    padding-top: 0;
  }
}

.givingCheckoutForm__giftRowRemove {
  margin-top: 1.6rem;
}

.givingCheckoutForm__giftRow + .givingCheckoutForm__addRowWrapper {
  margin-top: 3.2rem;
}

.givingCheckoutForm__addRowWrapper {
  display: flex;
  justify-content: center;

  @include mixins.medium {
    justify-content: flex-end;
  }
}

.givingCheckoutForm__addRowWrapper + .givingCheckout__total {
  margin-top: 8rem;
}

.givingCheckout__total {
  margin: 0;
  max-width: unset;

  text-align: center;

  @include mixins.medium {
    text-align: right;
  }
}
