@use 'sass:map';
@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.scrollIndicator {
  position: fixed;
  bottom: 8rem;
  right: 1rem;
  box-shadow: map-get(variables.$elevation-shadows, 'hover');
  border-radius: 3rem;
  background: colors.$color-white;
  padding: 0.5rem 1.5rem;

  font: 500 1.3rem/1.5 variables.$font-stack-default;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  transition: transform 0.3s;
  opacity: 0.95;
  z-index: map.get(variables.$z-index, dropdownlist);

  @include mixins.medium {
    display: none;
  }

  &--byebye {
    transform: translate(0, 12rem);
  }
}

.scrollIndicator__text + .scrollIndicator__icon {
  margin-left: 0.6rem;
}
