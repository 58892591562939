@use '../../utils/colors';
@use '../../utils/mixins';

.eventDateBlock {
  margin: 0;
  border-radius: 6px;
  border: solid 1px colors.$color-white;
  background-color: colors.$color-sunrise;
  padding: 0.8rem 1.2rem;

  text-align: center;
  color: colors.$color-black;

  @include mixins.medium {
    min-width: 13rem;
  }
}

.eventDateBlock__monthDay,
.eventDateBlock__year {
  text-transform: uppercase;
  color: colors.$color-black;

  @include mixins.medium {
    display: block;
  }
}

.eventDateBlock__year {
  font-weight: 400;
}

.eventDateBlock__monthDay {
  white-space: nowrap;
}
