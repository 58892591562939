@use 'sass:map';

@use '../utils/variables';
@use '../utils/colors';
@use '../utils/mixins';

.sortModalContainer {
  position: relative;
}

.sortMenu {
  position: absolute;
  top: 3.6rem;
  right: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  box-shadow: map-get(variables.$elevation-shadows, 'modal');
  min-width: 16rem;
  border-radius: 15px;
  border: 1px solid colors.$color-gray-2;
  background-color: colors.$color-white;
  padding: 0.8rem;

  z-index: map.get(variables.$z-index, modal-optionsmenu);

  @include mixins.medium {
    left: 1.2rem;
  }

  button {
    flex: 1;
  }
}
