@use '../../../utils/mixins';
@use '../../../utils/variables';
@use '../../../utils/colors';

// Add line and dot to the left when there are multiple job records together
// |
// •
// |
.jobRecord:not(:only-child) {
  position: relative;

  // | Vertical line
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -2rem; // move it left
    bottom: 0;
    border-left: solid 2px colors.$color-gray-2;

    @include mixins.medium {
      left: -4rem; // move it left by the column gap
    }
  }

  // Start the vertical line at the dot on the first job record
  // •
  // |
  &:first-child {
    &::before {
      top: 0.9rem;
    }
  }

  // End the vertical line at the dot on the last job record
  // |
  // •
  &:last-child {
    &::before {
      bottom: auto;
      height: calc(2.4rem + 0.9rem);
    }
  }

  .jobRecord__title {
    position: relative;

    // • Dot
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0.9rem;
      left: calc(-2rem - 4px); // move it left by gap + dot radius
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: colors.$color-gray-2;

      @include mixins.medium {
        left: calc(-4rem - 4px); // move it left by the column gap + dot radius
      }
    }
  }
}

.jobRecord__titleWrapper {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.jobRecord + .jobRecord {
  padding-top: 2.4rem;
}

.jobRecord__title,
.jobRecord__dates {
  margin: 0;
}

.jobRecord__titleWrapper + .jobRecord__dates {
  margin-top: 0.8rem;

  @include mixins.medium {
    margin-top: 1.6rem;
  }
}
