@use '../../utils/colors';
@use '../../utils/mixins';
@use '../../base/typography';

.eventConfirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eventConfirmation__message,
.eventConfirmation__email,
.eventConfirmation__transactionNumber,
.eventConfirmation__contact {
  margin: 0;

  text-align: center;
}

.eventConfirmation__message {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-bottom: solid 2px colors.$color-gray-3;
  padding-bottom: 4rem;

  @include mixins.medium {
    padding-bottom: 8rem;
  }

  p {
    @extend .t-paragraph--large; // from typography.scss
  }
}

.eventConfirmation__message + .eventConfirmation__email {
  margin-top: 3.2rem;

  @include mixins.medium {
    margin-top: 4rem;
  }
}

.eventConfirmation__email + .eventConfirmation__transactionNumber {
  margin-top: 2.4rem;
}

.eventConfirmation__email + .eventRegDetailsCard,
.eventConfirmation__transactionNumber + .eventRegDetailsCard {
  margin-top: 4rem;

  @include mixins.medium {
    margin-top: 8rem;
  }
}

.eventConfirmation__eventHeader {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  align-items: flex-start;

  @include mixins.medium {
    flex-direction: row;
    gap: 4rem;
  }
}

.eventConfirmation__eventDateTime,
.eventConfirmation__eventLocation {
  margin: 0;
}

.eventConfirmation__eventDateTime + .eventConfirmation__eventLocation {
  margin-top: 0.8rem;
}

.eventConfirmation__guestTable,
.eventConfirmation__additionalInfoTable {
  border: none;
  border-collapse: separate;
  border-spacing: 0 2.4rem;

  th {
    border: none;
    border-bottom: solid 1px colors.$color-gray-5;
    padding: 0 0 0.4rem 0;
    text-align: left;
  }

  td {
    border: none;
    padding: 0;
  }
}

.eventConfirmation__eventOptionCostColumn,
th.eventConfirmation__eventOptionCostColumn {
  text-align: right;
}

.eventConfirmation__backToHome:visited {
  color: colors.$color-white;
}

.eventRegDetailsCard + .eventConfirmation__contact,
.eventConfirmation__contact + .eventConfirmation__backToHome {
  margin-top: 8rem;
}
