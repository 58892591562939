@use '../utils/variables';
@use '../utils/colors';
@use '../utils/mixins';

.constituentAvatar {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  background: colors.$color-gray-2;

  font: 700 1.2rem/1.2 variables.$font-stack-default;
  letter-spacing: 0.07em;
  color: colors.$color-gray-5;
  text-decoration: none;

  overflow: hidden;

  &--extraSmall {
    @extend .constituentAvatar;
    width: 3.2rem;
    height: 3.2rem;
  }

  &--small {
    @extend .constituentAvatar;
    width: 4.5rem;
    height: 4.5rem;
  }

  &--large {
    @extend .constituentAvatar;
    width: 4.5rem;
    height: 4.5rem;

    font-size: 1.2rem;

    @include mixins.medium {
      width: 10.9rem;
      height: 10.9rem;

      font-size: 2.9rem;
    }
  }

  &--extraLarge {
    @extend .constituentAvatar;
    width: 13.6rem;
    height: 13.6rem;

    font-size: 2.9rem;

    @include mixins.medium {
      width: 16.8rem;
      height: 16.8rem;
    }
  }
}

.constituentAvatar__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
