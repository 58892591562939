@use '../../utils/mixins';
@use '../../utils/colors';

.upcomingEvents__content {
  max-height: 80vh;
  padding: 2.4rem 0.8rem 0.8rem 0.8rem;

  overflow: auto;

  @include mixins.medium {
    max-height: 40rem;
    padding: 2.4rem 3.2rem 3.2rem 3.2rem;
  }
}

.upcomingEvents__list {
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.eventCard__dateBlockWrapper + .eventCard__separatorWrapper {
  margin-top: 1.6rem;

  @include mixins.medium {
    margin-top: 0;
  }
}

.upcomingEventCard__content {
  flex: 1;

  @include mixins.medium {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;
  }
}

.eventCard__separatorWrapper {
  flex: 1;
  display: flex;

  // Separator line
  &::before {
    @include mixins.medium {
      content: '';
      display: block;
      border-radius: 5px;
      background-color: colors.$color-gray-3;
      margin: 0 1.2rem;
      width: 4px;
    }
  }
}

.upcomingEventCard__details {
  display: grid;
  gap: 0.4rem;

  p {
    margin: 0;
  }
}

.upcomingEventCard__title {
  color: colors.$color-black;

  &:visited,
  &:link {
    color: colors.$color-black;
  }
}

.upcomingEventCard__details + .eventAlert,
.eventAlert + .upcomingEventCard__register,
.upcomingEventCard__details + .upcomingEventCard__register {
  margin-top: 2.4rem;

  @include mixins.medium {
    margin-top: 0;
  }
}

.upcomingEventCard__location,
.upcomingEventCard__time,
.upcomingEventCard__cost {
  color: colors.$color-black;
}

.upcomingEventCard__register {
  display: block;

  @include mixins.medium {
    display: inline-block;
    min-width: unset;
  }
}
