@use '../../../utils/colors';
@use '../../../utils/variables';

.philanthropyReport__chartOuterWrapper {
  position: relative;
}

.philanthropyReport__chartTitle {
  padding-bottom: 1.2rem;
  border-bottom: solid 1px colors.$color-white;

  color: colors.$color-white;
  text-align: center;
}

.philanthropyReport__chartWrapper {
  width: 100%;
  height: 31.5rem;
  padding-bottom: 2.4rem;

  // X-axis labels
  .recharts-cartesian-axis-tick-value,
  .recharts-label {
    font: 700 1.6rem/1.2 variables.$font-stack-default;
    fill: colors.$color-gray-5;
    text-transform: uppercase;
  }

  // Vertical line indicating a data point
  .recharts-tooltip-cursor {
    stroke: colors.$color-black;
    stroke-width: 2px;
  }

  // Dot indicating a data point
  .recharts-active-dot circle {
    r: 8px; // SVG circles use a radius (r) instead of width and height
    fill: white;
    stroke: colors.$color-new-gold;
    stroke-width: 8px;
  }

  // Line that follows the top edge of the curve
  .recharts-area-curve {
    stroke: colors.$color-new-gold;
    stroke-width: 2px;
  }

  // Data points on the line
  .recharts-area-dot {
    stroke: colors.$color-new-gold;
    stroke-width: 4px;
    r: 5px; // SVG circles use a radius (r) instead of width and height
    fill: colors.$color-white;
    fill-opacity: 1;
  }

  .recharts-legend-wrapper {
    bottom: 0 !important;
  }

  .recharts-legend-item {
    // Gap between legend dot and label
    & > .recharts-surface {
      margin-right: 0.8rem !important;
    }

    // Gold part of legend dot
    .recharts-symbols {
      fill: colors.$color-new-gold;
    }
  }

  // Legend text
  .recharts-legend-item-text {
    position: relative;
    color: colors.$color-white !important;

    // Hack to put a white dot on top of the default legend gold dot
    &::before {
      content: '';
      position: absolute;
      top: 11px;
      left: -18px;
      display: block;
      height: 7px;
      width: 7px;
      background: white;
      border-radius: 50%;
    }
  }
}

// Background for the chart's labels (Q1, Q2, etc)
.philanthropyReport__chartXAxisBackdrop {
  background: colors.$color-gray-1;
  position: absolute;
  bottom: 5.5rem;
  left: 1.6rem;
  right: 1.6rem;
  height: 2.9rem;
  border-radius: 9px;
}

.fundChartTooltip {
  border-radius: 12px;
  border: solid 2px colors.$color-gray-4;
  background: colors.$color-white;
  padding: 1.6rem;
}

.fundChartTooltip__title {
  font-weight: 700;
  color: colors.$color-black;
}

.fundChartTooltip__title + .fundChartTooltip__item,
.fundChartTooltip__item + .fundChartTooltip__item {
  margin-top: 0.4rem;
}

.fundChartTooltip__item {
  font-weight: 500;
  color: colors.$color-gray-5;

  span {
    font-weight: 700;
  }
}
