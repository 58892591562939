@use '../../../utils/mixins';
@use '../../../utils/variables';
@use '../../../utils/colors';

.installment__header {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  padding: 1rem 0;

  &--pastDue {
    .installment__date,
    .installment__singleDesignation,
    .installment__total {
      color: colors.$color-alert;
    }
  }
}

.installment__header + .installment__detailsTable {
  border-top: solid 2px colors.$color-gray-2;
}

.installment__headerDescription {
  text-align: left;

  @include mixins.medium {
    display: flex;
  }
}

.installment__date,
.installment__singleDesignation {
  font: 700 1.4rem/1.5 variables.$font-stack-default;
  letter-spacing: 0.02em;
}

.installment__singleDesignation {
  display: block;

  @include mixins.medium {
    display: inline-block;
  }
}

.installment__date + .installment__singleDesignation {
  @include mixins.medium {
    margin-left: 1rem;
  }
}

.installment__total {
  font: 700 1.4rem/1.5 variables.$font-stack-default;
  letter-spacing: 0.02em;
}

.installment__detailsTable {
  margin: 0;
  background: colors.$color-gray-1;
  border: none;
}

.installment__tableBody {
  border: none;
}

.installment__row {
  border-bottom: solid 2px colors.$color-gray-2;
  background: colors.$color-white;

  &--pastDue {
    background: colors.$color-gray-1;

    .installment__balanceLabel,
    .installment__rowAmount {
      color: colors.$color-alert;
    }
  }
}

.installment__multiDesignation,
.installment__rowAmount {
  border: none;

  font: 400 1.4rem/1.5 variables.$font-stack-default;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.installment__multiDesignation,
.installment__partialPaymentLabel,
.installment__rowAmount,
.installment__balanceLabel,
.installment__balance {
  padding: 1rem 0;
}

.installment__multiDesignation {
  padding-right: 1rem;
}

.installment__rowAmount {
  padding-left: 1rem;
  text-align: right;
}

.installment__partialPaymentLabel {
  font: 400 italic 1.3rem/1.5 variables.$font-stack-default;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.installment__balanceLabel {
  font: 700 1.3rem/1.5 variables.$font-stack-default;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.installment__headerAmount {
  text-align: right;
}

.installment__pastDue {
  display: inline-block;
  font: 700 1.2rem/1.5 variables.$font-stack-default;
  color: colors.$color-alert;
  letter-spacing: 0.02em;

  @include mixins.medium {
    display: inline;
    white-space: nowrap;
  }
}

.installment__pastDue + .installment__total,
.installment__pastDue + .installment__rowAmount {
  margin-left: 1rem;
}

.installment__balance {
  text-align: right;
}
