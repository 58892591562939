@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.ellipsisButton {
  transform: rotate(90deg);

  @include mixins.medium {
    transform: rotate(0);
  }
}

.callout {
  border: none;
  flex: 1;
  align-self: stretch;

  @include mixins.medium {
    flex: none;
    margin-top: 10px;
  }
}

img.icon {
  margin-left: 70px;
  width: 120px;
  height: auto;
  background-color: colors.$color-alert;
}

.accordion-content {
  border: none;
  background-color: transparent;
}

.switch-inactive {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 40px;
}

input:checked + label > .switch-inactive.toggle {
  display: none;
  border-radius: 20px;
}

.classmatesSkeleton {
  padding: 3rem;
}

.classmateSkeleton {
  display: flex;
  gap: 1rem;
}

.classmateSkeleton + .classmateSkeleton {
  margin-top: 3rem;
}

.classmateSkeleton__details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
