@use 'sass:map';

@use '../../utils/variables';
@use '../../utils/colors';
@use '../../utils/mixins';

.constituentSearchInput__wrapper {
  width: 100%;
  max-width: unset;

  @include mixins.medium {
    width: auto;
    min-width: 60rem;
  }
}

.constituentSearchInput__wrapper + .constituentSearchResults {
  margin-top: 3.2rem;
}

.constituentSearchInput__clearButton {
  position: absolute;
  top: 50%;
  right: 2.4rem;

  transform: translateY(-50%);
}

.constituentSearchResults {
  padding: 1.6rem;

  border-radius: 1.6rem;
  border: solid 1px colors.$color-gray-2;
  background-color: colors.$color-white;
  box-shadow: map.get(variables.$elevation-shadows, 'container');

  @include mixins.medium {
    padding: 2.4rem 4rem;
  }
}

.constituentSearchResultsList {
  margin: 0;
  max-height: 40rem;
  overflow-y: auto;
  padding: 0;

  list-style-type: none;

  @include mixins.medium {
    padding: 1.2rem;
  }
}

.constituentSearchResultsList__item + .constituentSearchResultsList__item {
  margin-top: 0.8rem;
}

.constituentSearchResultsList__button {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  background-color: colors.$color-white;

  color: colors.$color-black;
  font-weight: 400;
  text-align: left;
}

.constituentSearchResultsList__icon {
  color: colors.$color-gray-3;
}

.constituentSearchResultsList__noResults {
  text-align: center;
}

.constituentSearchResultsList__noResults + .constituentSearchResults__footer,
.constituentSearchResultsList + .constituentSearchResults__footer {
  margin-top: 3.2rem;
  border-top: solid 2px colors.$color-gray-2;
  padding-top: 3.2rem;
}

.constituentSearchResults__footerTitle {
  margin: 0;

  text-align: center;
}

.constituentSearchResults__footerTitle + .constituentSearch__alternatives {
  margin-top: 2.4rem;
}

.constituentSearch__alternatives {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;

  @include mixins.medium {
    flex-direction: row;
  }
}

// Prevents an unauth user from manually entering their info to RSVP no
.confirmRsvpNoModal__unauthSearch {
  .eventRegistrationUserSearchFooter__addManuallyButton {
    display: none;
  }
}
