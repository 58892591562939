@use 'sass:map';
@use '../utils/mixins';
@use '../utils/variables';
@use '../utils/colors';

.navBar {
  box-shadow: map-get(variables.$elevation-shadows, 'accordion');
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: colors.$color-midnight;
}

.navBar__mobileHeader {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 3rem 1.5rem 2rem;

  @include mixins.extraLarge {
    display: none;
  }
}

.navBar__mobileLogo {
  display: flex;
  align-items: center;

  @include mixins.extraLarge {
    display: none;
  }
}

.navBar__mobileLogoHeart {
  height: 2.3rem;
}

.navBar__mobileLogoHeart + .navBar__mobileLogoWord {
  margin-left: 1rem;
}

.navBar__mobileLogoWord {
  height: 1.5rem;
}

.navBar__mobileNotLogoWrapper {
  display: flex;
  align-items: center;
}

.navBar__logoHeart {
  height: 2.3rem;
}

.navBar__logoHeart + .navBar__logoWord {
  margin-left: 1rem;
}

.navBar__logoWord {
  height: 2rem;
}

.navBar__menu {
  position: fixed;
  display: none;
  left: -200px;
  top: 0;
  height: 100%;
  transition: 0.3s ease;
  z-index: map.get(variables.$z-index, navigation-menu);

  &--open {
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    background: colors.$color-black;
    box-shadow: map-get(variables.$elevation-shadows, 'modal');
    padding: 1.5rem 3rem;
  }

  @include mixins.extraLarge {
    position: static;
    display: block;
  }
}

.navBar__toggle {
  min-width: unset;
  background: unset;
  padding: 0;

  font-size: 2.5rem;
  color: colors.$color-new-gold;

  @include mixins.extraLarge {
    display: none;
  }

  &--close {
    align-self: flex-end;
  }
}

.navBar__list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 1.5rem 3rem 1.5rem 2rem;

  list-style-type: none;

  @include mixins.extraLarge {
    flex-direction: row;
    align-items: center;
  }
}

.navBar__listItem {
  display: flex;
  justify-content: center;
  max-width: unset;

  &--logo {
    flex: 1;
    display: none;

    @include mixins.extraLarge {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

.navBar__listItem + .navBar__listItem {
  margin-top: 2rem;

  @include mixins.extraLarge {
    margin-top: 0;
    margin-left: 2rem;
  }
}

.navBar__link {
  min-width: 18rem;
  box-shadow: 0px 4px 15px #000000;
  border-radius: 50px;
  background-color: colors.$color-black;
  padding: 1rem;

  color: colors.$color-new-gold;
  text-decoration: none;
  font: 700 1.8rem/1.2 variables.$font-stack-default;
  letter-spacing: 0.02em;
  text-align: center;

  &:link,
  &:visited,
  &:active {
    color: colors.$color-new-gold;
  }

  &:hover {
    cursor: pointer;
  }
}

.navBarSkeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 2rem;

  @include mixins.extraLarge {
    flex-direction: row;
    justify-content: flex-end;
  }

  .skeletonText {
    min-width: 18rem;
    border-radius: 50px;
    background: colors.$color-black;

    @include mixins.loadingShimmerAnimationDark;

    @include mixins.extraLarge {
      min-width: unset;
    }
  }
}

.navBarSkeleton__notifications {
  display: none;

  @include mixins.extraLarge {
    display: block;
  }
}
