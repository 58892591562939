@use 'sass:map';
@use '../../utils/variables';
@use '../../utils/mixins';
@use '../../utils/colors';

.myNetwork__numConnections {
  vertical-align: middle;
}

.myNetwork__noConnections {
  margin: 0 auto;
  padding: 3.2rem;

  text-align: center;
}

.myNetworkList {
  margin: 0;
  padding: 1.6rem;
  height: 400px;
  overflow-y: scroll;

  list-style-type: none;

  @include mixins.medium {
    padding: 2.4rem;
  }
}

.myNetworkListItem {
  max-width: unset;
  @include mixins.medium {
    display: flex;
    gap: 1.6rem;
  }
}

.myNetworkListItem + .myNetworkListItem {
  margin-top: 2.4rem;
  border-top: solid 1px colors.$color-gray-5;
  padding-top: 2.4rem;

  @include mixins.medium {
    margin-top: 4.8rem;
    border: none;
    padding: 0;
  }
}

.myNetworkListItem__avatarName {
  display: flex;
  align-items: center;
  gap: 1.6rem;

  @include mixins.medium {
    flex: 1;
  }
}

.myNetworkList__personLocationJob {
  margin: 1.2rem 0 0 0;
}

.myNetworkListItem__avatarName + .myNetworkList__relationshipManagement {
  margin-top: 2.4rem;

  @include mixins.medium {
    margin-top: 0;
  }
}

.myNetworkList__relationshipManagement {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3.2rem;

  @include mixins.medium {
    justify-content: flex-end;
  }
}

.myNetworkListItem__selectRelationship {
  min-width: 20.8rem;
}

.myNetwork__deleteModal {
  max-width: 23.2rem;
  filter: drop-shadow(map.get(variables.$elevation-shadows, 'alert'));
  border-radius: 20px;
  background: colors.$color-white;
  padding: 3.2rem 2.4rem;

  text-align: center;
}

.myNetwork__deleteModalTitle {
  margin: 0;
}

.myNetwork__deleteModalTitle + .myNetwork__deleteModalMessage {
  border-top: solid 2px colors.$color-gray-2;
  padding-top: 2.4rem;
}

.myNetwork__deleteModalMessage + .myNetwork__deleteModalButtons {
  margin-top: 3.2rem;
  border-top: solid 2px colors.$color-gray-2;
  padding-top: 4rem;
}

.myNetwork__deleteModal .cancelButton {
  margin-top: 1.6rem;
}

// Skeleton
.myNetworkListSkeleton {
  padding: 2.4rem;
}

.myNetworkListSkeleton__row {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.myNetworkListSkeleton__row + .myNetworkListSkeleton__row {
  margin-top: 2rem;
}

.myNetworkListSkeleton__text {
  flex: 1;

  .skeletonText + .skeletonText {
    margin-top: 1rem;
  }
}
