@use '../../utils/mixins';
@use '../../utils/variables';

.magicLink__greeting {
  margin: 0;
}

.magicLink__greeting + .magicLink__textContent,
.magicLink__textContent + .magicLink__illustration {
  margin-top: 2.4rem;
}

.magicLink__textContent + .magicLink__button {
  margin-top: 4rem;
}

.magicLink__button {
  display: block;
  margin-top: 4rem;
  margin-left: 0;
  min-height: 6rem;
  width: 100%;
  max-width: 48rem;
}

.magicLink__magicLetter,
.magicLink__magicLetterSent {
  display: none;

  @include mixins.medium {
    display: block;
    max-height: 43rem;
    width: 100%;
    object-fit: contain;
  }
}

.magicLink__magicLetterSent {
  @include mixins.medium {
    margin-top: 0;
    margin-bottom: 8rem;
    max-height: 48rem;
  }
}
