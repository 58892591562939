@use '../../utils/mixins';

.myEvents__content {
  padding: 2.4rem 0.8rem 0.8rem 0.8rem;

  @include mixins.medium {
    padding: 2.4rem 3.2rem 3.2rem 3.2rem;
  }
}

.myEvents__none {
  text-align: center;

  @include mixins.medium {
    text-align: left;
  }
}

.myEvents__listWrapper {
  max-height: 80vh;
  overflow-y: scroll;

  @include mixins.medium {
    max-height: 30rem;
    padding-right: 2.4rem;
  }
}

.myEvents__list {
  margin: 0;
  padding: 0;
}
