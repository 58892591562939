@use 'sass:map';

@use '../../utils/variables';
@use '../../utils/colors';

$border-width: 2px;
$vertical-padding: 2rem;
$horizontal-padding: 3rem;
$line-height: 1.2;

// Shift the label up and out of the way
%boxField__label--shifted {
  padding: 0.4rem 0.8rem;

  font-size: 1.4rem;
  color: colors.$color-black;

  transform: translate(1rem, -50%);
}

.boxField {
  position: relative;
  max-width: 40rem;

  input,
  textarea {
    width: 100%;
    border-radius: 10px;
    border: solid $border-width colors.$color-gray-3;
    background: colors.$color-white;
    padding: $vertical-padding $horizontal-padding;

    font: 500 1.8rem / $line-height variables.$font-stack-default;
    color: colors.$color-black;

    &:focus {
      box-shadow: none;
      border-color: colors.$color-black;

      &::placeholder {
        opacity: 1;
      }
    }

    &::placeholder {
      opacity: 0;
      transition: 0.2s ease-in-out;
    }

    // Shift the label up when the user is typing in the field
    &:focus + label {
      @extend %boxField__label--shifted;
    }

    // Keep the label shifted up when the field has content
    &:not(:placeholder-shown) + label {
      @extend %boxField__label--shifted;
    }

    &:disabled,
    &:read-only {
      background: colors.$color-gray-1;
    }

    &:disabled + label,
    &:read-only + label {
      background: colors.$color-gray-1;
    }

    &:disabled:not(:placeholder-shown) + label,
    &:read-only:not(:placeholder-shown) + label {
      background: colors.$color-white;
    }
  }

  input {
    min-height: 6.6rem; // makes date fields behave in iOS Safari

    appearance: unset; // makes date fields behave in iOS Safari
  }

  label {
    border-radius: 5px;
    background: colors.$color-white;
    position: absolute;
    top: 0;
    left: 0;

    font: 500 1.8rem / $line-height variables.$font-stack-default;
    color: colors.$color-gray-4;

    transform: translate(
      calc($vertical-padding + $border-width),
      calc($horizontal-padding + $border-width - 50%)
    );
    transition: 0.2s ease-in-out;
  }

  &--error {
    input,
    textarea {
      box-shadow: map.get(variables.$elevation-shadows, 'alert');
      border-color: colors.$color-alert;

      &:focus + label,
      &:not(:placeholder-shown) + label {
        color: colors.$color-alert;
      }
    }

    label {
      color: colors.$color-alert;
    }
  }
}
