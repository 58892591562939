@use '../../utils/colors';
@use '../../utils/mixins';
@use '../../base/typography';

.eventCard__dateBlockWrapper + .myEventCard__details {
  margin-top: 1.6rem;

  @include mixins.medium {
    margin-top: 0;
  }
}

.myEventCard__details {
  flex: 1;
  margin-right: 1.6rem;

  @include mixins.medium {
    display: flex;
    justify-content: space-between;
    gap: 3.2rem;
  }
}

.myEventCard__detail,
.myEventCard__title {
  margin: 0;
}

.myEventCard__detailColumn {
  @include mixins.medium {
    flex: 1;
  }

  p {
    margin: 0;
    @extend .t-paragraph--small;
    color: colors.$color-black;
  }
}

.myEventCard__detailColumn + .myEventCard__detailColumn,
.myEventCard__detailColumn + .button {
  margin-top: 3.2rem;

  @include mixins.medium {
    margin-top: 0;
  }
}

.myEventCard__title {
  color: colors.$color-black;

  &:link,
  &:visited {
    color: colors.$color-black;
  }
}

.myEventCard__detail,
.myEventCard__detail:link,
.myEventCard__detail:visited {
  color: colors.$color-black;
}

.myEventCard__detail + .myEventCard__detail {
  display: block;
  margin-top: 0.4rem;

  font-weight: 400;
}
