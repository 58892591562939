@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

// Date range
.dateRange {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-top: 2.5rem;

  @include mixins.medium {
    flex-direction: row;
  }
}

.dateRange__legend {
  display: block;
  margin-bottom: 1.6rem;
}

.dateRange__startDate,
.dateRange__endDate {
  flex: 1;

  @include mixins.medium {
    width: 0; // forces date input to shrink horizontally
  }
}

// Reduce space between date text and calendar picker
::-webkit-calendar-picker-indicator {
  margin-left: 1rem;
  padding: 0;
}

.dateRangeMMDD__to {
  margin-top: 1.5rem;

  font: 700 1.5rem/1 variables.$font-stack-default;
  color: colors.$color-gray-4;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.dateSelectMMDD__legend {
  margin-bottom: 1.6rem;
}

.dateSelectMMDD__fields {
  display: flex;
  align-items: center;

  .boxField--select {
    flex: 1;
  }
}

.dateSelectMMDD__slash {
  margin: 0rem 0.8rem;

  font: 700 1.8rem/1 variables.$font-stack-default;
  color: colors.$color-gray-5;
}
