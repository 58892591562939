@use '../../utils/mixins';
@use '../../utils/colors';

.myNetwork__searchFilter {
  margin-top: -20px;
  border-radius: 10px;
  border: solid 1px colors.$color-gray-2;
  background: colors.$color-gray-1;
  padding: 4.4rem 1.2rem 2.4rem 1.2rem;

  @include mixins.medium {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 5.6rem;
    border: none;
    border-radius: 0;
    background: none;
    padding: 4rem 0 3.2rem 0;
    border-bottom: solid 2px colors.$color-gray-3;
  }

  .boxField {
    margin: 0;
    max-width: unset;
  }
}

.myNetwork__search {
  @include mixins.large {
    min-width: 50.4rem;
  }
}

input.myNetwork__search {
  padding-left: 6.4rem;
}

.myNetworkSearch__wrapper {
  position: relative;
}

.myNetworkSearch__wrapper + .boxField {
  margin-top: 3.2rem;

  @include mixins.medium {
    margin-top: 0;
  }
}

.myNetworkSearch__magnifyingGlass {
  position: absolute;
  top: 50%;
  left: 2.4rem;

  font-size: 2.4rem;
  color: colors.$color-gray-4;

  transform: translateY(-50%);
}

.myNetworkSearch__fakePlaceholder {
  position: absolute;
  top: 50%;
  left: 6.4rem;

  color: colors.$color-gray-4;

  transform: translateY(-50%);
  pointer-events: none;
}

.myNetworkSearch__clear {
  position: absolute;
  top: 50%;
  right: 2.4rem;

  transform: translateY(-50%);
}

.myNetwork__relationshipFilter {
  min-width: 20.8rem;

  @include mixins.medium {
    margin-right: 2.1rem; // horizontally align the filter dropdown with the relationship type dropdowns below it
  }
}
