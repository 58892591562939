@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.landingPage {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-color: colors.$color-midnight;
  background-image: url('../../../components/LandingPage/images/wave-bottom-mobile.svg');
  background-size: 498px 177px;
  background-position: right -120px bottom 0;

  @include mixins.medium {
    display: flex;
    flex-direction: column;
    background-image: url('../../../components/LandingPage/images/wave-bottom-desktop.svg');
    background-size: 1257px 557px;
    background-position: right -350px bottom -80px;
  }

  @include mixins.extraLarge {
    background-position: right -275px bottom -80px;
  }
}

.landingPage__main {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8rem;
}
