@use '../../../utils/mixins';
@use '../../../utils/variables';
@use '../../../utils/colors';

.editEmployerGroupForm {
  padding-top: 2rem; // for the first one
}

.employerGroup + .employerGroup {
  .editEmployerGroupForm {
    padding-top: 4.4rem;
  }
}

//TODO Temp style until we get to styling this for reals
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

.employerOptions,
.jobFieldSet__options {
  // vertically align options button with the first field
  margin-top: 3.5rem;
}

.profileRecord + .jobFieldSet {
  margin-top: 4rem;
}

.boxField + .jobFieldSet__fields {
  margin-top: 2.4rem;
}

.jobFieldSet__fields {
  margin-bottom: 4rem;
  border-bottom: dashed 2px colors.$color-gray-2;
  padding-bottom: 4rem;
}

.radio + .selectJobPrivacy__message {
  margin-top: 3rem;
}

.selectJobPrivacy__message {
  margin: 0 auto;
  max-width: 14rem;

  font: 400 1.4rem/1.5 variables.$font-stack-default;
  text-align: center;
}

.employerOptions__areYouSureClose {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.editEmployerGroupForm__controls {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-top: 2rem;
  border-bottom: solid 2px colors.$color-gray-2;
  padding-bottom: 4rem;

  @include mixins.medium {
    flex-direction: row-reverse;
    max-width: 43.5rem;
  }

  .cancelButton,
  .submitButton {
    flex: 1;
  }
}
