@use '../utils/mixins';

.pageErrorBoundary {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.6rem;

  text-align: center;
}

.pageErrorBoundary__image {
  border-radius: 8px;
  max-width: 100%;

  @include mixins.medium {
    max-width: 75%;
  }

  @include mixins.extraLarge {
    max-width: 50%;
  }
}

.pageErrorBoundary__title,
.pageErrorBoundary__details {
  margin: 0;
}

.pageErrorBoundary__image + .pageErrorBoundary__title {
  margin-top: 4rem;
}

.pageErrorBoundary__title + .pageErrorBoundary__details {
  margin-top: 3.2rem;
}

.pageErrorBoundary__details + .button--primary {
  margin-top: 2.4rem;
}

.button--primary + .pageErrorBoundary__details {
  margin-top: 4rem;
}
