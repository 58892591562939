@use '../../utils/colors';
@use '../../utils/mixins';

.eventCard {
  max-width: unset;

  border-radius: 10px;
  border: solid 1px colors.$color-gray-3;
  background-color: colors.$color-gray-1;
  padding: 1.6rem;

  @include mixins.medium {
    display: flex;
    align-items: center;
  }
}

.eventCard + .eventCard {
  margin-top: 3.2rem;
}
