@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.iAm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mixins.medium {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4.8rem;
    padding: 7.2rem 5.6rem;
    background-color: colors.$color-ghost-white;
    border-radius: 24px;
  }
  @include mixins.extraLarge {
    flex-wrap: nowrap;
  }
}

.iAm__typeButton {
  min-width: unset;
  display: flex;
  width: 85%;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  border: none;
  border-radius: 11px;
  box-shadow: map-get(variables.$elevation-shadows, 'modal');

  text-decoration: none;
  font-weight: 500;
  font-size: 2.4rem;

  @include mixins.medium {
    flex-direction: column;
    width: unset;
    min-height: 26rem;
    flex-basis: 24rem;
    font-size: 3.2rem;
  }

  @include mixins.extraLarge {
    flex: 1 1;
  }
  svg {
    color: colors.$color-black;
  }

  &--selected {
    background-color: colors.$color-black;
    color: colors.$color-white;

    svg {
      color: colors.$color-new-gold;
    }
  }
}

.iAm__typeButton + .iAm__typeButton {
  margin-top: 2.5rem;

  @include mixins.medium {
    margin-top: 0;
  }
}

.iAm__typeButtonSelectedContainer {
  position: relative;
}

.iAm__typeButton--selectedCheck {
  @include mixins.medium {
    position: absolute;
    top: 100%;
    left: 50%; // moves it to the right by half the container's width
    transform: translateX(-50%);
  }
}
