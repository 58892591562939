@use '../../utils/colors';
@use '../../utils/mixins';

.guestManagement {
  text-align: center;
}

// Want to bring a guest?
.guestManagement__icon {
  font-size: 4rem;
  color: colors.$color-new-gold;
}

.guestManagement__icon + .guestManagement__question {
  margin-top: 1.6rem;
}

.guestManagement__question + .eventReg__yesNoButtons {
  margin-top: 3.2rem;
}

// Search for a guest
.guestManagment__searchInstructions {
  text-align: center;
}

.guestManagment__searchInstructions + .constituentSearch {
  margin-top: 3.2rem;
}

// Add unnamed guest
.guestManagement__addFormTitle {
  text-align: center;
}

.guestManagement__addFormTitle + .addConstituentForm {
  margin-top: 3.2rem;
}

.addConstituentForm {
  @include mixins.medium {
    min-width: 40rem;
  }
}

.guestManagement__cancelButton {
  margin-top: 3.2rem;
}
