@use 'sass:map';
@use '../../../utils/mixins';
@use '../../../utils/variables';
@use '../../../utils/colors';

.claimedClassmatesEllipsis__unclaimButton,
.claimedClassmatesEllipsis__updateButton,
.claimedClassmatesEllipsis__cancelButton {
  width: 100%;
}

.claimedClassmatesEllipsis__unclaimButton
  + .claimedClassmatesEllipsis__updateButton,
.claimedClassmatesEllipsis__unclaimButton
  + .claimedClassmatesEllipsis__AddNoteWrapper,
.claimedClassmatesEllipsis__cancelButton + .addNoteCallout {
  margin-top: 1rem;
}

.ellipsisPopup {
  display: block;
  position: absolute;
  padding: 1rem;
  top: 15%;
  right: 15px;
  box-shadow: map-get(variables.$elevation-shadows, 'modal');
  border-radius: 21px;
  background-color: colors.$color-white;

  z-index: map.get(variables.$z-index, modal-optionsmenu);

  @include mixins.medium {
    top: 75px;
    right: 0;
  }
}

.claimedClassmatesEllipsis__AddNoteWrapper {
  position: fixed;
  top: 1rem;
  left: 1rem;
  right: 1rem;
  padding: 1rem;
  box-shadow: map-get(variables.$elevation-shadows, 'modal');
  border-radius: 9px;
  border: none;
  background: white;

  @include mixins.medium {
    position: static;
    box-shadow: unset;
    padding: 0;
  }
}
