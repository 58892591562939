@use '../../utils/mixins';

.dateRangeMMDD + .submitButton,
.textField + .submitButton,
.dateRange + .submitButton,
.intlPhoneField + .submitButton,
.radio + .submitButton,
// .textField + .auth__nextButton,
fieldset + .submitButton {
  margin-top: 3.2rem;
}
