@use '../../utils/mixins';

.profileSubsectionLayout {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  @include mixins.medium {
    flex-direction: row;
    gap: 6.4rem;
  }
}

.profileSubsectionLayout + .profileSubsectionLayout {
  margin-top: 3.2rem;

  @include mixins.medium {
    margin-top: 4rem;
  }
}

.profileSubsectionLayout__left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 25%;

  @include mixins.medium {
    flex-direction: column;
    align-items: flex-end;
    justify-content: unset;
  }
}

.profileSubsectionLayout__right {
  @include mixins.medium {
    flex: 1;
  }
}
