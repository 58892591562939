@use '../utils/mixins';
@use '../utils/variables';

.contactUs__formWrapper {
  margin: 0 auto;

  @include mixins.large {
    max-width: 50rem;
  }
}
