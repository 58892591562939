@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.checkbox {
  & + & {
    margin-top: 1rem;
  }
}

.checkbox--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.checkboxInput {
  @include mixins.showFocusOnLabel('.checkboxLabel');
}

.checkboxInput:checked + .checkboxLabel {
  font-weight: 400;

  // Show custom 'checked' checkbox check
  &::after {
    opacity: 1;
  }

  &::before {
    background-color: colors.$color-gray-5;
  }
}

.checkboxInput:disabled + .checkboxLabel {
  &::before {
    background-color: colors.$color-gray-5;
  }
}

.checkboxLabel {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0;

  font-size: 1.8rem;
  font-family: variables.$font-stack-default;

  &:hover {
    cursor: pointer;
  }

  // Create custom checkbox box
  &::before {
    content: '';
    display: block;
    flex-shrink: 0;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1.5rem;
    border-radius: 0.4rem;
    border: solid 2px colors.$color-gray-5;
  }

  // Create custom 'checked' checkbox check
  &::after {
    content: '';
    display: block;
    position: absolute;
    // center vertically for Safari
    top: 50%;
    transform: translateY(-50%);
    left: 0.6rem;
    width: 1.8rem;
    height: 1.8rem;
    background-image: url('../../../images/Icons/faCheck.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    opacity: 0;
    transition: 0.25s opacity;
  }
}

.checkbox + .checkbox {
  margin-top: 1.5rem;
}
