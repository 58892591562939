@use 'sass:map';

@use '../../utils/mixins';
@use '../../utils/variables';

.quickFindModalForm__wrapper {
  box-shadow: map-get(variables.$elevation-shadows, 'modal');
  border-radius: 20px;
  padding: 1rem;
}

.quickFindModalForm {
  .submitButton {
    min-width: unset;
  }
}

.quickFindModalForm__selectWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  @include mixins.medium {
    flex-direction: row;
    align-items: center;
  }

  .searchResultContainer {
    box-shadow: none;
    padding: 0;
  }
}

.quickFindModalForm__select {
  flex: 1;
}

.quickFindModalForm__addBtn {
  flex: 0;
  align-self: flex-start;
  height: 6rem;
  padding: 0rem 2.5rem;
}
