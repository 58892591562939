@use 'sass:map';

@use '../utils/mixins';
@use '../utils/variables';
@use '../utils/colors';

.publicNavBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: map-get(variables.$elevation-shadows, 'accordion');
  background-color: colors.$color-white;
  padding: 1.5rem 2rem;

  z-index: map-get(variables.$z-index, 'navigation');
}

.publicNavBar__logo {
  height: 4rem;

  @include mixins.medium {
    height: 8rem;
  }
}

.landingPage__contactLink + .publicNavBar__signInButton {
  @include mixins.medium {
    margin-left: 5rem;
  }
}
