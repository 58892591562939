@use '../utils/mixins';
@use '../utils/variables';

button + .addNoteCallout {
  margin-top: 1.6rem;
}

.addNoteCallout {
  border: none;
}

.addNote__buttonWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 2.4rem;
  max-width: 40rem;

  @include mixins.medium {
    flex-direction: row-reverse;
    justify-content: center;
  }
}

.addNoteCallout__successMsg {
  text-align: center;
}
