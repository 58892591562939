@use '../utils/mixins';

.componentLibrary__avatars {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.componentLibrary__avatars + .componentLibrary__avatars {
  margin-top: 2rem;
}

.componentLibrary__radioButtons {
  @include mixins.medium {
    display: flex;
    justify-content: space-between;
  }
}

.componentLibrary__selectFieldsTable {
  margin-top: 2.4rem;

  th:first-child,
  td:first-child {
    width: 14rem;
  }
}
