@use '../../utils/mixins';
@use '../../utils/colors';

.toggleSwitch--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.toggleSwitch__input {
  @include mixins.showFocusOnLabel('.toggleSwitch__label');
}

.toggleSwitch__input:checked + .toggleSwitch__label {
  &::before {
    background-color: colors.$color-new-gold;
  }

  &::after {
    transform: translate(3rem, -50%);
  }
}

.toggleSwitch__label {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;

  &::before {
    content: '';
    display: block;
    height: 3rem;
    width: 6rem;
    border-radius: 16px;
    background-color: colors.$color-gray-5;

    transition: background-color 0.5s;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0.2rem;
    top: 50%;
    height: 2.6rem;
    width: 2.6rem;
    border-radius: 50%;
    background: colors.$color-white;

    transform: translateY(-50%);
    transition: transform 0.5s;
  }
}

.toggleSwitch + .toggleSwitch {
  margin-top: 1.5rem;
}
