@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.scholarships__addNote__submitBtn {
  width: 15rem;
  top: 4rem;
  margin-right: 15rem;
  padding: 1rem;
}

.scholarships__addNote__cancelBtn {
  top: 19.5rem;
  margin-left: 48.5rem;
  width: 15rem;
  height: 3.5rem;
}

.scholarships__addEditButtonContainer {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  height: 5.5rem;
  align-items: center;
  background: colors.$color-gray-2;
  border-radius: 1rem;
}

.scholarships__list {
  margin-top: 3.2rem;
}

.scholarships__subtitle {
  margin: 0 0.8rem;

  color: colors.$color-slate;
}

.scholarships__donorList {
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.scholarships__donor {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.scholarships__donor + .scholarships__donor {
  margin-top: 2.4rem;
}

.scholarshipsSkeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  padding: 6rem;
}
