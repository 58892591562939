@use 'sass:map';

@use '../../../utils/mixins';
@use '../../../utils/variables';
@use '../../../utils/colors';

.myClassmates__panelContent {
  padding: 2rem;
}

.myClassmates__searchWrapper {
  display: flex;
  align-items: baseline;
}

.myClassmates__searchForm {
  position: relative;

  .advancedSearch__moreButton {
    bottom: 4rem;
  }
}

.sortModalContainer--myClassmates {
  flex: 0;
}

.classmates__listWrapper {
  width: 100%;
  height: 400px;

  @include mixins.medium {
    height: 600px;
  }
}

.myClassmates__row {
  display: flex;
  padding: 10px 10px 10px 0px;

  @include mixins.medium {
    padding: 25px 10px 25px 0px;
  }
}

.myClassmates__userInfo {
  flex: 1;
}

.myClassmates__topHats {
  margin-top: 1rem;
}

.myClassmates__avatar {
  flex: 0;
}

.myClassmates__avatar + .myClassmates__userInfo {
  margin-left: 25px;
  width: 10rem;
}

.myClassmates__name {
  display: block;
}

.myClassmates__claimStatusWrapper {
  display: flex;
  justify-content: center;
  flex: 0 0 17%;
}

.myClassmates__claimButton,
.myClassmates__claimed {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 60px;
  background-position: center center;
  background-size: contain;
}

.myClassmates__claimed {
  &::before {
    background-color: colors.$color-new-gold;
    border: solid 4px colors.$color-new-gold;
    font-weight: 900;
  }
}

.myClassmates__claimStatus {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  line-height: 2rem;
  font-family: variables.$font-stack-default;

  @include mixins.medium {
    text-align: left;
  }

  &--success {
    font-size: 1.5rem;
    color: colors.$color-success;
  }
}

.myClassmates__ellipsisContainer {
  position: relative;
  flex: 0;
}

.myClassmates__ellipsisBtn {
  transform: rotate(90deg);
}

.myClassmates__ellipsisMenu {
  display: flex;
  justify-content: center;
  background: colors.$color-white;
  position: absolute;
  top: 0;
  left: -115px;
  right: 0;
  width: 115px;
  box-shadow: map-get(variables.$elevation-shadows, 'modal');
  border-radius: 9px;
  padding: 10px;
}

#iMyClassmateClaimed {
  height: auto;
  width: 10%;
}

#sMyClassmatesClaimedBy {
  word-wrap: normal;
  display: table-caption;
  width: 350px;
  font-size: medium;
  margin-left: 0px;
}

#sMyClassmates_Plus {
  margin-left: 400px;
}

#sMyClassmates_Minus {
  margin-left: 400px;
}

#sMyClassmates_Title {
  padding-left: 5px;
  padding-right: 505px;
}

#sMyClaimedClassmates_Title {
  padding-left: 5px;
  padding-right: 425px;
}

#sMyClaimedClassmates_Plus {
  margin-left: 400px;
}

#sMyClaimedClassmates_Minus {
  margin-left: 400px;
}

#sMyClassmates_CityStateEmployer {
  color: gray;
  width: 800px;
  font-size: 1.2rem;
  font-family: variables.$font-stack-default;
}

#iMyClassmatesClaim_Button {
  height: auto;
  width: 45%;
}

#gMyClassmatesClaim {
  display: grid;
  margin-left: 750px;
  justify-content: center;
  align-content: flex-start;
}

#gMyClassmates_NameAddress {
  display: grid;
  align-content: flex-start;
  width: 50px;
}

#cMyClassmates_Name {
  width: 600px;
  align-content: flex-start;
  font-size: 1.4rem;
  font-family: variables.$font-stack-default;
  font-weight: bold;
}

#cMyClassmates_CityStateEmployer {
  width: 800px;
  align-content: flex-start;
  justify-content: center;
}

#gMyClassmates {
  margin-left: 000px;
  align-content: flex-start;
}

#h1MyClassmates_Volunteer {
  width: 100px;
  font-size: 3rem;
  font-weight: 700;
  font-family: variables.$font-stack-default;
}

#cMyClassmates_Score {
  margin-left: -75px;
  align-content: flex-start;
  width: 24px;
  height: 24px;
}

#dMyClassmates {
  width: 1200px;
  margin-left: 350px;
  align-content: flex-start;
}

#gMyClaimedClassmates {
  margin-left: 18px;
  align-content: flex-start;
}

#aiMyClassmates {
  width: 100px;
  font-size: 3em;
  font-family: variables.$font-stack-default;
}

/* My Claimed Classmates */
#gMyClaimedClassmatesEllipsisMenu {
  margin-left: 175px;
  display: inline-block;
}

#cMyClaimedClassmates_EmailAddress {
  margin-left: 102px;
}

#sMyClaimedClassmates_EmailAddress {
  padding-right: 5px;
}

#cMyClaimedClassmates_Phone {
  margin-left: 102px;
}

#sMyClaimedClassmates_Phone {
  padding-right: 5px;
}

#sMyClaimedClassmates_ProspectName {
  margin-left: 2px;
  display: inline-block;
  font-size: 2.2rem;
  font-weight: bold;
  font-family: variables.$font-stack-default;
}

#cMyClaimedClassmates_AddressEmployer {
  color: gray;
  width: 600px;
}

#starProspectName {
  padding-left: 5px;
}

#cMyClaimedClassmates_Involvements {
  margin-left: 100px;
  width: 700px;
  text-align: left;
  display: flex;
}

#sMyClaimedClassmates_Involvements {
  padding-right: 5px;
  text-align: center;
}

#cMyClaimedClassmates_HomecomingStatus {
  margin-left: 100px;
}

#sMyClaimedClassmates_HomecomingStatus {
  padding-right: 5px;
  vertical-align: top;
}

#cMyClaimedClassmates_ReunionDonor {
  margin-left: 100px;
}

#sMyClaimedClassmates_ReunionDonor {
  padding-right: 5px;
  vertical-align: top;
}

#sMyClaimedClassmates_Options {
  margin-left: -28px;
  position: relative;
  top: -10px;
}

#iStar {
  height: auto;
  width: 5%;
}

#sMyClaimedClassmates_Star {
  padding-left: 5px;
  position: relative;
  top: -5px;
}

#claimSpan {
  align-self: center;
}

#iUnclaimClassmate_BrokenStar {
  height: auto;
  width: 75%;
}

#gMyClaimedClassmates {
  display: inline-block;
}

.MyClaimedClassmates_Involvements_Person {
  position: relative;
  bottom: 5px;
}

#sMyClaimedClassmates_UpAndDownArrows {
  display: ruby-text-container;
}

#dMyClassmates {
  font-size: 1.4rem;
  margin-left: 0;
  height: 1500px;
  font-family: variables.$font-stack-default;
}

#cMyClassmatesClaim_ReunionProspectTeamStatus {
  margin-left: -200px;
  width: 400px;
}

#dMyClaimedClassmates {
  font-size: 1.4rem;
  margin-left: -20px;
  font-family: variables.$font-stack-default;
}
