@use 'sass:map';

@use '../utils/variables';
@use '../utils/colors';
@use '../utils/mixins';

.notifications__wrapper {
  position: relative;
}

.notifications__wrapper + .navBar__toggle {
  margin-left: 2.4rem;
}

.notifications__bell {
  position: relative;
  min-width: unset;
  background: unset;
  padding: unset;

  color: colors.$color-new-gold;
  font-size: 2.8rem;

  &:hover {
    cursor: pointer;
  }

  &--unread {
    @extend .notifications__bell;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0.3rem;
      right: 0;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background: colors.$color-alert;
    }
  }
}

.notificationsModal {
  position: absolute;
  top: 100%;
  right: 50%;
  max-width: calc(100vw - 4rem);
  box-shadow: map-get(variables.$elevation-shadows, 'modal');
  border-radius: 8px 0 8px 8px;
  background: colors.$color-white;

  z-index: map.get(variables.$z-index, modal);

  @include mixins.medium {
    width: 32rem;
  }
}

.notificationsModal__titleWrapper {
  border-bottom: solid 2px colors.$color-gray-2;
  padding: 2.4rem;
}

.notificationsModal__title {
  margin: 0;
  color: colors.$color-slate;
}

.notificationsModal__title + .notificationsModal__list,
.notificationsModal__title + .notification__title {
  margin-top: 2rem;
}

.notificationsModal__list {
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.notificationsModal__listItem {
  border-bottom: solid 2px colors.$color-gray-2;
}

.notification__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: unset;
  padding: 2.4rem;

  &:hover {
    filter: none;
  }

  &::after {
    content: '';
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    background: colors.$color-gray-2;
  }

  &--unread {
    @extend .notification__header;

    &::after {
      background: colors.$color-salmon;
    }
  }

  &--open {
    @extend .notification__header;
    border-bottom: dashed 2px colors.$color-gray-2;
  }
}

.notification__content {
  padding: 1.6rem 2.4rem 4rem 2.4rem;
}

.notificationsModal__markAllRead {
  margin: 3.2rem 2.4rem;
}

.notificationsModal__noNotifications {
  padding: 3.2rem 7.2rem 4.8rem 7.2rem;

  text-align: center;
}

.notificationsModal__successIcon {
  font-size: 2rem;
  color: colors.$color-black;
}

.verifyProfileInfoNotification__text {
  margin: 0;
}

.notification__answerBtn {
  margin-top: 3.2rem;
}

.notification__success {
  text-align: center;
}
