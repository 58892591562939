@use '../../utils//colors';
@use '../../utils/mixins';

.eventCardSkeleton__event {
  border-radius: 10px;
  border: solid 1px colors.$color-gray-2;
  background-color: colors.$color-gray-1;
  padding: 1.6rem;
  height: 9.6rem;
  display: flex;
  gap: 2.4rem;
}

.eventCardSkeleton__event + .eventCardSkeleton__event {
  margin-top: 3.2rem;
}

.eventCardSkeleton__eventDate {
  flex: 1;
  border-radius: 6px;
  min-width: 13rem;
  background-color: colors.$color-sunrise;
}

.eventCardSkeleton__eventDetails {
  flex: 4;

  @include mixins.medium {
    flex: 15;
  }

  .skeletonText + .skeletonText {
    margin-top: 1.6rem;
  }
}
