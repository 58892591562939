@use 'sass:map';

@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.fileUpload {
  display: flex;
  flex-direction: column;
  align-items: center;

  .field__errorMessage {
    text-align: center;
  }
}

.fileUpload__input {
  @include mixins.showFocusOnLabel('.fileUpload__label');
}

.fileUpload__label {
  overflow: hidden;
  max-width: 18rem;
  width: 18rem;
  display: block;
  border-radius: 10px;
  border: solid 1px colors.$color-gray-3;
  background: colors.$color-gray-2;
  padding: 1.3rem;

  font: 700 1.8rem/1 variables.$font-stack-default;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    cursor: pointer;
  }

  &--error {
    box-shadow: map.get(variables.$elevation-shadows, 'alert');
    border-color: colors.$color-alert;

    .fa-upload {
      color: colors.$color-alert;
    }
  }
}
