@use '../../utils/mixins';
@use '../../utils/colors';

.eventRegistrationNav {
  align-self: stretch;

  @include mixins.medium {
    align-self: unset;
    padding-bottom: 8.8rem;
  }
}

.eventRegistrationNav__primaryList {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  list-style-type: none;

  @include mixins.medium {
    justify-content: unset;
    gap: 8rem;
  }
}

.eventRegistrationNav__primaryLink {
  display: block;
  padding-bottom: 1.2rem;

  font-weight: 600;

  &:link {
    color: colors.$color-black;
    text-decoration: none;
  }
}

.eventRegistrationNav__primaryLink.active {
  border-bottom: solid 4px colors.$color-new-gold;
}

.eventRegistrationNav__secondaryList {
  position: absolute;
  top: calc(100% + 2.4rem);
  left: 50%;
  max-width: 80rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin: 0;
  border-radius: 8px;
  border: solid 1px colors.$color-gray-2;
  background-color: colors.$color-ghost-white;
  padding: 0.8rem;

  list-style-type: none;

  overflow-y: auto;
  transform: translateX(-50%);
}

.eventRegistrationNav__secondaryLink {
  display: block;

  text-decoration: none;
  color: colors.$color-black;
  font-weight: 600;
  white-space: nowrap;

  &.active {
    border-radius: 8px;
    background: colors.$color-white;
    padding: 0.8rem 1.6rem;
  }
}
