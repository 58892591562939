@use '../utils/variables';
@use '../utils/colors';

.betaTag {
  border-radius: 0.5rem;
  padding: 0.3rem 0.5rem;

  font: 700 1.4rem/1 variables.$font-stack-default;
  vertical-align: middle;
  text-transform: none;
  letter-spacing: 0.1rem;

  &--gold {
    background: colors.$color-new-gold;
  }
}
