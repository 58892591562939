@use 'sass:map';

@use '../utils/mixins';
@use '../utils/variables';
@use '../utils/colors';

.feedback {
  display: block;
  margin: 2rem auto 2rem auto;

  @include mixins.medium {
    display: block;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    margin: 0;

    z-index: map.get(variables.$z-index, 'modal');
  }

  .addNoteCallout {
    margin: 0;
    padding: 0;
  }
}

.feedback__content {
  box-shadow: map-get(variables.$elevation-shadows, 'modal');
  border-radius: 20px;
  background: colors.$color-white;
  padding: 2rem;

  text-align: center;
}

.feedback__thumbButtons {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.feedback__thumbButton--up {
  color: colors.$color-success;
}

.feedback__thumbButton--down {
  color: colors.$color-alert;
}

.feedback__header + .feedback__thumbButtons,
.feedback__thumbButtons + .feedback__addNoteTrigger {
  margin-top: 1.5rem;
}

.feedback__header + .feedback__thanks,
.feedback__thanks + .feedback__addNoteTrigger {
  margin-top: 3rem;
}
