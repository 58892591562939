@use 'sass:map';

@use '../../../utils/variables';

.gdpr__modal {
  box-shadow: map-get(variables.$elevation-shadows, 'modal');
  border-radius: 1rem;
  padding: 1.5rem;
}

.gdpr__statement + .gdpr__form {
  margin-top: 2rem;
}

.gdpr__form .submitButton {
  margin-top: 2rem;
}
