@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.checkoutConfirmation__title {
  margin: 0;
  color: black;

  // // @extend.textSans--24med;
  // // @extend.textAllCaps;
}

.checkoutConfirmation__title + .checkoutConfirmation__textWrapper {
  margin-top: 2rem;

  @include mixins.medium {
    margin-top: 8rem;
  }
}

.checkoutConfirmation__textWrapper {
  margin: 0 auto;
  max-width: 63rem;
}

.checkoutConfirmation__text {
  margin: 0;

  // // @extend.textSans--24;
}

.checkoutConfirmation__contactInfo {
  // // @extend.textSans--24bold;
}

.checkoutConfirmation__textWrapper + .checkoutConfirmation__transactionSummary {
  margin-top: 2rem;
  @include mixins.medium {
    margin-top: 8rem;
  }
}

.checkoutConfirmation__transactionSummary {
  margin: 0 auto;
  max-width: 72rem;
}

.checkoutConfirmation__transaction {
  border-top: solid 2px #dbdbdb;
  padding-top: 2rem;

  font: 400 italic 2.4rem/1.5 variables.$font-stack-default;
  text-align: center;

  @include mixins.medium {
    padding-top: 7rem;
  }
}

.checkoutConfirmation__transaction + .checkoutConfirmation__summary {
  margin-top: 3rem;

  @include mixins.medium {
    margin-top: 6rem;
  }
}

.checkoutConfirmation__summaryContent {
  padding: 2rem;

  @include mixins.medium {
    padding: 4rem;
  }
}

.checkoutConfirmation__transactionSummary + .checkoutConfirmation__done,
.checkoutConfirmation__done + .checkoutConfirmation__textWrapper {
  margin-top: 4rem;

  @include mixins.medium {
    margin-top: 8rem;
  }
}

.checkoutConfirmation__done {
  display: block;
  margin: 0 auto;
  max-width: 40rem;

  text-align: center;

  &:visited {
    color: colors.$color-white;
  }
}

.checkoutConfirmation__transactionId {
  display: block;

  @include mixins.medium {
    display: inline-block;
  }
}
