@use 'sass:map';

@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.searchContainer + .accordion,
.accordion + .pageLayout__content--background {
  margin-top: 1.6rem;

  @include mixins.medium {
    margin-top: 3.2rem;
  }
}

.profileRecord {
  display: flex;
  gap: 2.4rem;
}

.profileRecord__content {
  flex: 1;
}

.profileRecord__options {
  flex: 0;
}

.profileRecord + .profileRecord,
.profileRecord + .Collapsible {
  margin-top: 1.6rem;

  @include mixins.medium {
    margin-top: 2.4rem;
  }
}

.newRecordForm + .Collapsible,
.newRecordForm + .profileRecord,
.newRecordForm + .newRecordForm {
  margin-top: 2rem;
}

.Collapsible + .newRecordForm,
.profileRecord + .newRecordForm {
  margin-top: 2.4rem;
}

.newRecordForm__controls {
  margin-top: 2rem;
  display: flex;
  flex-direction: column-reverse;
  gap: 2.5rem;
  max-width: 40rem;

  @include mixins.medium {
    flex-direction: row;
  }

  .cancelButton,
  .submitButton {
    flex: 1;
  }
}

.profileSubsectionLayout + .personal__moreBtnWrapper {
  margin-top: 3.2rem;

  @include mixins.medium {
    margin-top: 4.8rem;
  }
}

.personal__moreBtnWrapper {
  transition: 400ms linear;

  @include mixins.medium {
    display: flex;
    justify-content: flex-end;
    width: 25%;
  }

  &--expanded {
    @extend .personal__moreBtnWrapper;
    margin-bottom: 3.2rem;

    @include mixins.medium {
      margin-bottom: 4.8rem;
    }
  }
}

.record + .deceasedLabel {
  margin-top: 0.5rem;
}
