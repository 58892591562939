@use '../../utils/mixins';

.profileSkeleton {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mixins.medium {
    display: block;
  }
}

.profileSkeleton__avatar + .profileSkeleton__textGroup,
.skeletonText + .profileSkeleton__sectionContentRight {
  margin-top: 1.6rem;
}

.profileSkeleton__textGroup + .profileSkeleton__sectionHeader {
  margin-top: 5.6rem;
}

.profileSkeleton__sectionHeader + .profileSkeleton__sectionContent {
  margin-top: 2.4rem;
}

.profileSkeleton__textGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;

  @include mixins.medium {
    align-items: flex-start;
  }
}

.profileSkeleton__sectionContent {
  width: 100%;

  @include mixins.medium {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
  }
}

.profileSkeleton__sectionContentRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
