@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.profileSection {
  @include mixins.medium {
    padding-right: 5.6rem;
  }
}

.profileSection + .profileSection {
  margin-top: 4.8rem;

  @include mixins.medium {
    margin-top: 5.6rem;
  }
}

.profileSectionHeader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mixins.medium {
    gap: 0.8rem;

    &::after {
      content: '';
      display: block;
      flex: 1;
      border-top: 4px solid colors.$color-gray-2;
    }
  }
}

.profileSectionHeader__titleWrapper {
  width: calc(100% - 2.5rem);

  @include mixins.medium {
    flex-basis: 16.8rem;

    text-align: right;
  }
}

.profileSectionHeader__title {
  margin: 0;
  border-radius: 12px;
  border: 1px solid colors.$color-gray-2;
  background: colors.$color-gray-1;
  padding: 1.3rem 1.9rem;

  @include mixins.medium {
    display: inline-block;
  }
}

.profileSectionHeader__editButton {
  position: absolute;
  right: 0;

  @include mixins.medium {
    position: static;
    order: 1;
  }
}

.profileSectionHeader + .profileSectionContent {
  margin-top: 3.2rem;

  @include mixins.medium {
    margin-top: 4rem;
  }
}

.profileSectionContent {
  padding: 0 0.8rem;

  @include mixins.medium {
    padding: 0;
  }
}
