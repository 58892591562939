@use '../../utils/colors';

.field__helpText {
  margin: 1.2rem;

  color: colors.$color-gray-5;
}

.field__errorMessage {
  margin: 1.2rem 0;
  padding-left: 1rem;

  color: colors.$color-alert;

  &::before {
    content: '* ';
  }
}
