@use 'sass:map';
@use '../../../utils/variables';
@use '../../../utils/colors';
@use '../../../utils/mixins';

.philanthropyReport__content {
  padding: 2.4rem 0.8rem 0.8rem 0.8rem;
}

.philanthropyReport__fundIntro {
  margin: 0 auto;
  text-align: center;
}

.philanthropyReport__fundIntro + .philanthropyReport__pdfButton,
.philanthropyReport__fundIntro + .philanthropyReport__fundOverview,
.philanthropyReport__pdfButton + .philanthropyReport__fundOverview {
  margin-top: 3.2rem;
}

.philanthropyReport__overviewContainer {
  box-shadow: map.get(variables.$elevation-shadows, 'container');
  border-radius: 12px;
  border: solid 1px colors.$color-mist;
  background-color: colors.$color-slate;
}

.philanthropyReport__fundOverviewTitle {
  margin-top: 1.2rem;
  margin-bottom: 0;

  color: colors.$color-white;
  text-align: center;
}

.fundOverview__stat {
  margin: 1.6rem auto;

  text-align: center;
  color: colors.$color-white;
}

.fundOverview__statValue {
  font-weight: 700;
}

.philanthropyReport__fundContactList {
  border-top: solid 1px colors.$color-white;
  padding: 1.2rem 2.4rem;

  list-style-type: none;

  @include mixins.medium {
    display: flex;
    gap: 4.8rem;
    justify-content: center;
  }
}

.philanthropyReport__fundContact + .philanthropyReport__fundContact {
  margin-top: 1.6rem;

  @include mixins.medium {
    margin: 0;
  }
}

.philanthropyReport__fundContact {
  display: flex;
  gap: 0.8rem;
  justify-content: space-between;
  align-items: center;

  color: colors.$color-white;
}

.philanthropyReport__fundContactLink:link,
.philanthropyReport__fundContactLink:visited {
  color: colors.$color-white;
}

.philanthropyReport__overviewContainer
  + .philanthropyReport__overviewContainer {
  margin-top: 4.8rem;
}

.philanthropyReport__overviewContainer
  + .philanthropyReport__scholarshipContent {
  margin-top: 3.2rem;
}

.philanthropyReport__recipientsContent {
  border-top: solid 3px colors.$color-gray-2;
}

.recipientsList {
  // Required for virtualized list
  height: 400px;
}

.recipient {
  padding: 1.5rem 0rem;
}

.recipient__nameAvatarWrapper {
  display: flex;
}

.recipient__nameAvatarLink {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.recipient__name {
  margin-bottom: 0;
}

.recipient__nameAvatarWrapper + .recipient__infoSection,
.recipient__infoSection + .recipient__infoSection {
  margin-top: 2.4rem;
}

.recipient__textLabel {
  display: inline-block;
  margin: 0;
}

// Spacing between label and content
.recipient__textLabel + .recipient__textContent,
.recipient__textLabel + .recipient__thankYou {
  margin-top: 0.8rem;
}

.recipient__textContent {
  margin: 0;

  white-space: pre-line; // makes linebreaks \n work
}

.recipient__thankYou + .recipient__thankYou {
  margin-top: 1rem;
}
