.eventRegistrationContactInfo__instructions {
  text-align: center;
}

.eventRegistrationContactInfo__instructions + .eventReg__yesNoButtons,
.eventRegistrationContactInfo__instructions + form {
  margin-top: 8rem;
}

.signInToChangeEmailModal__buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.6rem;

  p {
    margin: 0;
  }
}
