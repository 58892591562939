@use './boxField';

// Vertically center $ with field content
.dollarAmountTextField__dollarSign {
  position: absolute;
  top: calc(
    #{boxField.$vertical-padding} + #{boxField.$line-height}rem + #{boxField.$border-width}
  );
  left: 0;
  transform: translate(calc(-100% - 0.8rem), -50%);
}
