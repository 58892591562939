@use '../../../utils/variables';
@use '../../../utils/colors';

.installmentsPaid__content {
  padding: 0 2rem 2rem 2rem;
}

.installmentsPaid__table {
  margin: 0;
  border: none;
}

.installmentsPaid__tableBody {
  border: none;
}

.installmentsPaid__row {
  border-bottom: solid 2px colors.$color-gray-2;
  background: colors.$color-white;

  &:first-child {
    border-top: solid 2px colors.$color-gray-2;
  }
}

.installmentsPaid__date,
.installmentsPaid__amount {
  border: none;
  padding: 1rem 0;

  font: 700 1.4rem/1.5 variables.$font-stack-default;
  letter-spacing: 0.02em;
}

.installmentsPaid__amount {
  text-align: right;
}

.installmentsPaid + .pledge__initialAmountWrapper {
  margin-top: 3rem;
}
