@use '../../../utils/colors';

.align-top > .recordPrivacyIcon {
  margin-top: 0.7rem; // vertically center with text
}

.recordPrivacyIcon {
  display: inline-block;

  .tooltip__triggerButton {
    padding: 0;

    color: colors.$color-salmon;
    font-size: 1.6rem;
  }
}
