@use '../../utils/variables';
@use '../../utils/colors';

.checkoutSummary__header {
  border: none;
  background: transparent;
}

.checkoutSummary__columnHeader {
  text-align: left;

  &:last-child {
    text-align: right;
  }
}

.checkoutSummary__row {
  border-top: solid 2px colors.$color-gray-2;
  border-bottom: solid 2px colors.$color-gray-2;
  background-color: colors.$color-white;
}

.checkoutSummary__description {
  padding: 2rem 1rem;
}

.checkoutSummary__amount {
  padding: 2rem 1rem;

  text-align: right;
}
