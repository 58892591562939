@use 'sass:map';

@use '../../utils/colors';
@use '../../utils/variables';
@use '../../utils/mixins';

.eventCancellationModal__message {
  font-style: italic;
}

.eventCancellationModal___eventList {
  margin: 0;
  padding: 0 2.4rem 0 0;

  list-style-type: none;

  &--collapsed {
    display: flex;
  }

  &--expanded {
    max-height: 30.4rem;
    overflow-y: auto;
  }
}

.eventCancellationModal__backConfirm {
  display: flex;
  gap: 1.6rem;
  flex-direction: column-reverse;

  @include mixins.medium {
    justify-content: center;
    flex-direction: row;
  }
}
