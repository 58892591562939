@use 'sass:map';

@use '../utils/mixins';
@use '../utils/colors';
@use '../utils/variables';

$large-expandable-section-rounded: 16px;

.accordion {
    &--smallUnderline {
    box-shadow: none;
    border: none;
  }

  &--small {
    box-shadow: map.get(variables.$elevation-shadows, 'accordion');
    border-radius: 7px;
    background-color: colors.$color-white;
  }

  &--medium {
    border-radius: 7px;
    background-color: colors.$color-gray-2;
  }

  &--large {
    position: relative;
    min-height: 8rem;
    padding-top: calc(
      8rem - $large-expandable-section-rounded
    ); // makes the panel title overlap content

    @include mixins.medium {
      min-height: 6rem;
      padding-top: calc(
        6rem - $large-expandable-section-rounded
      ); // makes the panel title overlap content
    }
  }
}

.accordion + .accordion {
  margin-top: 2rem;
}

.accordion__header {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;

  padding: 1.6rem 1.6rem 1.6rem 2.4rem;

  &--smallUnderline {
    @extend .accordion__header;
    padding: .8rem;
    background: colors.$color-white;
  }

  &--small {
    @extend .accordion__header;
    background: colors.$color-white;
    padding: 1.6rem;

    &.is-open {
      box-shadow: map.get(variables.$elevation-shadows, 'accordion');
    }

    
  }

  &--medium {
    @extend .accordion__header;
    background: colors.$color-gray-2;

    &.is-open {
      box-shadow: map.get(variables.$elevation-shadows, 'accordion');
    }
  }

  &--large {
    @extend .accordion__header;
    position: absolute;
    top: 0;
    box-shadow: map.get(variables.$elevation-shadows, 'accordion');
    border-radius: $large-expandable-section-rounded;
    min-height: 8rem;
    background: colors.$color-white;

    @include mixins.medium {
      min-height: 6rem;
    }

    &:focus:not(:focus-visible) {
      // Make sure the shadow is still visible when the accordion is focused by a mouse click
      box-shadow: map.get(variables.$elevation-shadows, 'accordion');
    }
  }
}

.accordion__title {
  margin: 0;
  flex: 1;

  text-align: left;

  &--smallUnderline {
    @extend .accordion__title;
    font-size: 1.8rem;
    font-weight: 400;
    text-decoration: underline;
  }

  &--small{
    @extend .accordion__title;
    font-size: 1.8rem;
    font-weight: 400;
  }

  &--medium {
    @extend .accordion__title;
    font-size: 2.2rem;
    font-weight: 400;
    color: colors.$color-black;
  }

  &--large {
    @extend .accordion__title;
    display: flex;
    align-items: center;
    gap: 2rem;

    font-size: 2rem;
    color: colors.$color-gray-4;
  }
}

.accordion__titleImage {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
}

.accordion__headerToggleIconContainer {
  &--smallUnderline,
  &--small,
  &--medium {
    color: colors.$color-gray-5;
  }

  &--large {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: colors.$color-gray-2;
    padding: 1rem;

    color: colors.$color-gray-5;
  }
}

.accordion__panel {
  &--large {
    box-shadow: map.get(variables.$elevation-shadows, 'accordion');
    border-bottom-left-radius: $large-expandable-section-rounded;
    border-bottom-right-radius: $large-expandable-section-rounded;
    background: colors.$color-white;
    padding-top: $large-expandable-section-rounded;
  }
}

.accordion__panelInner {
  &--smallUnderline {
    padding: 1.6rem;
  }
  
  &--small {
    padding: 1.6rem;
  }

  &--medium {
    padding: 3.2rem 1.6rem 6.4rem 1.6rem;
  }
}
