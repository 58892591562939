@use 'colors';

$font-stack-default: 'Open Sans', 'Segoe UI', 'Helvetica Neue', sans-serif;

$z-index: (
  navigation-close: 211,
  navigation-menu: 210,
  navigation: 200,
  modal-profileview: 111,
  modal: 100,
  modal-overlay: 212,
  modal-optionsmenu: 98,
  spinner: 20,
  dropdownlist: 10,
);

/*
 * Shadows
 * 
 * Example usage:
 *   filter: drop-shadow(map.get(variables.$elevation-shadows, 'hover'));
 *   box-shadow: map.get(variables.$elevation-shadows, 'hover');
*/
$elevation-shadows: (
  'highlight': 0 0 5px colors.$color-gray-3,
  'hover': 0 0 8px colors.$color-gray-5,
  'modal': 0 0 10px colors.$color-gray-3,
  'accordion': 0 0 18px #d0d6e2,
  'container': 0 0 18px #d0d6e2,
  'alert': 1px 1px 4px colors.$color-alert,
);
