@use '../utils/colors';

.componentErrorBoundary {
  margin: 1.6rem;
  border-radius: 8px;
  background-color: colors.$color-gray-5;
  padding: 1.6rem;

  p {
    color: colors.$color-white;
  }
}
