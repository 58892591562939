@use '../../utils/mixins';
@use '../../utils/colors';

.landingPage__footer {
  flex: 0;
  padding: 8rem 0;

  text-align: center;

  @include mixins.medium {
    padding: 5rem;
    text-align: left;
  }

  @include mixins.extraLarge {
    padding: 5rem 15rem;
  }
}

.landingPage__footerLink {
  color: colors.$color-white;

  &:link,
  &:visited {
    color: colors.$color-white;
  }
}

.landingPage__copyright {
  color: colors.$color-white;
}

.landingPage__footerLink + .landingPage__footerLink {
  &::before {
    content: '|';
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
