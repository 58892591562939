@use 'sass:map';

@use '../utils/mixins';
@use '../utils/variables';
@use '../utils/colors';

.pageLayout {
  margin: 0 auto;
  max-width: 90rem;
  padding: 1rem;
}

.pageLayout__title {
  margin: 0 0 3.2rem 0;
}

.pageLayout__content--background {
  box-shadow: map-get(variables.$elevation-shadows, 'container');
  border-radius: 16px;
  background-color: colors.$color-white;
  padding: 3.2rem 1.6rem;

  @include mixins.medium {
    padding: 8rem 8rem 12rem;
  }
}
