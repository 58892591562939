@use '../../utils/colors';

.scholarships__subtitle + .scholarships__fundOriginStoryWrapper {
  margin-top: 0.8rem;
}

.scholarships__fundOriginStoryWrapper {
  position: relative;
}

.scholarships__fundOrigin {
  margin: 0 0.8rem;
}

.scholarships__fundOrigin--truncated {
  position: relative;
  margin: 0 0.8rem;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4rem;
    display: block;
    background: linear-gradient(
      180deg,
      rgba(colors.$color-white, 0) 15.56%,
      colors.$color-gray-2 100%
    );
  }
}

.scholarships__fundOrigin--truncated + .scholarships__fundOriginViewMore {
  position: absolute;
  right: 0;
  bottom: 0;
}

.scholarships__fundOriginViewMore {
  margin-top: 0.8rem;
}

.scholarships__fundOriginStoryWrapper + .scholarships__donorAccordion {
  margin-top: 3.2rem;
}
