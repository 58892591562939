@use '../utils/mixins';
@use '../utils/variables';
@use '../utils/colors';
@use './typography';

* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  font-size: 62.5% !important;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    background-color: colors.$color-white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: colors.$color-gray-4;
  }
}

// Visually hidden, but still visible to
// screen readers. Only use if required.
.hide_accessibly {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

/* Sitewide Scrollbars */

// Overall scrollbar width
::-webkit-scrollbar {
  width: 10px;
}

// Scrollbar background
::-webkit-scrollbar-track {
  background-color: colors.$color-gray-1;
}

// Scrollbar itself
::-webkit-scrollbar-thumb {
  background-color: colors.$color-gray-3;
  border-radius: 20px;
}

// Style outlines for users navigating site via keyboard
input,
textarea,
select,
button,
a {
  @include mixins.focus;
}

a {
  font: 500 1.8rem/1.2 variables.$font-stack-default;
  color: colors.$color-gray-5;
  text-decoration: underline;

  &:link,
  &:visited,
  &:active {
    color: colors.$color-gray-5;
  }
}

// Tables
table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border: solid 1px colors.$color-gray-3;
}

caption {
  @extend .t-paragraph--large;
}

th {
  border: solid 1px colors.$color-gray-3;
  padding: 1rem;
  @extend .t-paragraph--medium;
}

td {
  border: solid 1px colors.$color-gray-3;
  padding: 1rem;
  @extend .t-paragraph;
}

legend {
  @extend .t-paragraph--medium;
}

.app__skipToContentLink {
  position: absolute;
  left: -100000px;
  display: inline-block;
  margin: 1.6rem;

  &:focus,
  &:focus-visible {
    position: static;
  }
}

hr {
  border-top: solid 2px colors.$color-gray-2;
  width: 100%;
}
