@use 'sass:map';

@use '../utils/variables';
@use '../utils/colors';

.helpRequest__popup {
  position: absolute;
  right: 50%;
  box-shadow: map-get(variables.$elevation-shadows, 'modal');
  border-radius: 15px;
  background: colors.$color-white;
  padding: 1rem;
  width: 22rem;

  .addNoteCallout {
    margin: 0;
    padding: 0;
  }
}

.helpRequest__title + .addNoteCallout,
.helpRequest__statusIconWrapper + .addNoteCallout,
.addNoteCallout + .helpRequest__doneBtn {
  margin-top: 1.6rem;
}

.helpRequest__statusIconWrapper {
  text-align: center;
}

.helpRequest__successIcon {
  font-size: 2.5rem;
  color: colors.$color-success;
}

.helpRequest__errorIcon {
  font-size: 2.5rem;
  color: colors.$color-alert;
}

.helpRequest__doneBtn {
  width: 100%;
}
