@use '../utils/mixins';
@use '../utils/colors';

.skeletonText {
  max-width: 100%;
  border-radius: 0.5rem;
  background: colors.$color-gray-2;

  @include mixins.loadingShimmerAnimation;

  &--small {
    height: 1rem;
  }

  &--medium {
    height: 2rem;
  }

  &--large {
    height: 4rem;
  }
}
