@use 'sass:map';
@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.searchContainer {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  box-shadow: map-get(variables.$elevation-shadows, 'accordion');
  border: none;
  border-radius: 21px;
  background-color: colors.$color-white;

  transition: 0.7s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.searchSkeleton {
  display: flex;
  gap: 1.6rem;
  padding: 1.6rem;

  &:first-child {
    flex: 0;
  }

  &:last-child {
    flex: 1;
  }
}

.search__magnifyIcon {
  flex: 0;
  padding: 1.6rem 1.5rem 1.6rem 2.4rem;

  font-size: 2.4rem;
  color: colors.$color-gray-4;
}

.quickSearchSelect__input {
  width: 100%;
  border: none;
  background-color: colors.$color-white;
  padding: 0;
}

.search__advSearchToggle {
  font-size: 1.32rem;

  &:hover {
    cursor: pointer;
  }
}

.quickSearch__wrapper {
  flex: 1;
}

.quickSearch__container {
  flex: 1;
  position: relative;
}

.quickSearchSelect__clearBtnText {
  font-size: 0;

  &::placeholder {
    font-weight: 700;
  }
}

.quickSearchSelectInput__wrapper {
  position: relative;
}

.quickSearchSelect__resultsList {
  position: absolute;
  width: 99%;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  filter: drop-shadow(map.get(variables.$elevation-shadows, 'accordion'));
  background: colors.$color-white;

  z-index: map.get(variables.$z-index, dropdownlist);
}

.quickSearchResult__wrapper {
  padding: 0.8rem;
}

.quickSearchResult {
  background: colors.$color-white;
  text-align: left;

  &:hover {
    filter: none;
  }
}

.quickSearchResult__icon {
  font-size: 1.8rem;
  color: colors.$color-gray-3;
}

.quickSearchResult__icon + .quickSearchResult__name {
  margin-left: 1.5rem;
}

.quickSearchResult__name {
  font: 400 1.8rem/1.5 variables.$font-stack-default;
  color: colors.$color-black;
}

.quickSearchSelect__clearBtn {
  position: absolute;
  top: 50%;
  right: 0;

  font-size: 2rem;

  transform: translateY(-50%);
}

.quickSearchSelect__noResultsMsg {
  margin: 0 auto;
  padding: 1.6rem;

  text-align: center;
}

.quickSearchSelect__errorMsg {
  margin: 0 auto;
  padding: 1.6rem;

  text-align: center;
  color: colors.$color-alert;
}

.quickSearchResultsListSkeleton {
  padding: 2.4rem;
}

.quickSearchResultsListSkeleton__item {
  display: flex;
  gap: 1.6rem;
}

.quickSearchResultsListSkeleton__item + .quickSearchResultsListSkeleton__item {
  margin-top: 2.4rem;
}
