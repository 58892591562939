@use 'sass:map';

@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.authLayout__wrapper {
  max-width: 200rem;
  min-height: 100vh;
  margin: auto;
  padding: 2.4rem;

  @include mixins.medium {
    h2 {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  @include mixins.extraLarge {
    padding: 3.2rem 8rem;
    border-radius: 10px;
    box-shadow: map.get(variables.$elevation-shadows, 'container');
  }
}

.authLayout__header {
  @include mixins.large {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.authLayout__header + .authLayout__progressBar {
  margin-top: 4rem;
}

.authLayout__logo {
  width: 20rem;
}

.authLayout__identityProviderAccountManagement {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.2rem;
}

.authLayout__identityProviderAccount {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;

  color: colors.$color-black;

  &:after {
    content: '';
    display: block;
    height: 0.8rem;
    width: 0.8rem;
    border-radius: 50%;
    background: colors.$color-success;
  }

  img {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.authLayout__identityProviderAccountSignOut {
  white-space: nowrap;
  color: colors.$color-black;
}

.authLayout__progressBar {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.authLayout__progressBarStep {
  width: 4rem;
  height: 0.8rem;
  border-radius: 16px;
  background-color: colors.$color-mist;

  &--visited {
    @extend .authLayout__progressBarStep;
    background-color: colors.$color-black;
  }

  &--current {
    @extend .authLayout__progressBarStep;
    background-color: colors.$color-new-gold;
  }
}

.authLayout__progressBar + .authLayout__progressLabel {
  margin-top: 1.4rem;
}

.authLayout__progressLabel {
  margin: 0;
}

.authLayout__columns {
  input,
  textarea {
    border: 2px solid colors.$color-midnight;
  }

  @include mixins.medium {
    display: flex;
    gap: 4.8rem;
  }
}

.authLayout__column {
  flex: 1;
}

.authLayout__footer {
  .authLayout__identityProviderAccountManagement {
    flex-direction: column;
    margin-top: 4rem;
  }
}

.authLayout__footerButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  @include mixins.medium {
    flex-direction: row;
  }
}

.authLayout__helpBtn {
  align-self: center;
  @include mixins.medium {
    display: inline-block;
    margin-left: auto;
  }
}

.authLayout__continueBtn,
.authLayout__backBtn {
  min-height: 6rem;
}

.authLayout__backBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  @include mixins.medium {
    order: -1;
  }
}

.authLayout__header + .authLayout__childrenWrapper,
.authLayout__childrenWrapper + .authLayout__footer,
.authLayout__header + .authLayout__columns,
.authLayout__columns + .authLayout__footer {
  margin-top: 6rem;
}
