@use '../../utils/mixins';
@use '../../utils/variables';
@use '../../utils/colors';

.scholarships__donorAccordion + .scholarships__thankYouNotes {
  margin-top: 5.6rem;
}

.scholarships__subtitle + .addRecordButton,
.scholarships__subtitle + .addNoteCallout {
  margin-top: 2.4rem;
}

.scholarships__thankYouNote__submitBtn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 15rem;
}

.scholarships__thankYouNoteBody {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}

.scholarships__thankYouNoteNotEditableMsg {
  font: 400 italic 1.2rem/1.5 variables.$font-stack-default;
  text-align: right;
}

.scholarships__donorExpEmail {
  text-decoration: underline;
}
