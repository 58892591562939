@use 'sass:map';

@use '../../../utils/mixins';
@use '../../../utils/variables';
@use '../../../utils/colors';

.myClassmatesSearch {
  flex: 1;
}

.myClassmatesSearch--open {
  box-shadow: map-get(variables.$elevation-shadows, 'accordion');
  border-radius: 21px;

  .myClassmates__searchHeader {
    border: none;
    border-radius: 21px;
  }

  .myClassmates__searchContent {
    display: block;
  }
}

.myClassmates__searchHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: solid 2px colors.$color-gray-2;
  border-top: solid 2px colors.$color-gray-2;
  border-radius: unset;
  background: colors.$color-white;
  padding: 0 1rem 0 1rem;
  height: 6rem;
  font-weight: 500;
  margin-bottom: 10px;
  font-family: variables.$font-stack-default;
  color: colors.$color-black;
  font-size: 2rem;

  &:hover {
    cursor: pointer;
    filter: unset;
  }

  @include mixins.large {
    font-size: 2.4rem;
    height: 7rem;
  }
}

.myClassmates__searchHeaderLeft {
  text-align: left;
}

.myClassmates__searchHeaderText {
  margin-left: 15px;
}

.myClassmates__searchContent {
  display: none;
  padding: 0px 15px 15px 15px;

  &::before {
    content: '';
    display: block;
    margin-bottom: 15px;
    border-top: solid 4px colors.$color-gray-2;
  }
}

.myClassmatesSearch_viewClaimableToggle {
  display: flex;
  justify-content: flex-end;
}

.myClassmatesSearch__classYearSelect {
  flex: 0 0 49%;
}

.myClassmatesSearch__classYearClaimableToggleWrap {
  margin-bottom: 2.4rem;

  &--withClassSelector {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .myClassmatesSearch__classYearSelect
    + .myClassmatesSearch_viewClaimableToggle {
    margin-top: 2rem;

    @include mixins.medium {
      margin: 0;
    }
  }
}

.myClassmatesSearch__classYearClaimableToggleWrap
  + .advSearch__basicFieldsWrap {
  margin-top: 2rem;
}
