@use '../../utils/colors';
@use '../../utils/mixins';

.registrantListTray {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 4rem;
  border-radius: 8px;
  border: solid 1px colors.$color-gray-2;
  background-color: colors.$color-ghost-white;
  padding: 1.6rem;

  @include mixins.medium {
    flex-direction: row;
    gap: unset;
    justify-content: space-around;
    margin: 19.2rem 10% 0 10%;
    padding: 1.6rem 3.2rem;
  }
}

.registrantListTray__heading {
  margin: 0;
}

.registrantListTray__noRegistrantsMsg {
  color: colors.$color-gray-5;
}

.registrantListTray__heading + .registrantListTray__registrants {
  margin-top: 1.2rem;
}

.registrantListTray__registrants {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.registrantListTray__registrant {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.4rem;

  & + & {
    margin-top: 1.6rem;
  }
}
